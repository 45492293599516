import { useArticleForm } from '@/routes/_protected/articles/-contexts';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import logger from '@/utils/Logger';
import FieldLogger from '@/routes/_protected/articles/-components/FieldLogger';
import {
  ArticleOptionNameEnum,
  ArticleOptionValueEnum,
  type BuildingUsageEnum,
} from '@/types/schemaEnums';
import { TextField } from '@daangn/carotene';
import { getBuildingLedger } from '@/graphql/getBuildingLedger';
import { unionBy } from 'lodash-es';
import SearchJusoDialog from '@/routes/_protected/articles/-components/SearchJusoDialog';
import type { ArticleFormInputType } from '@/scheme/article';

type Props = {
  disabled?: boolean;
};

export type AddressFormState = Pick<
  ArticleFormInputType,
  'address' | 'fullAddress' | 'coordinate' | 'corRealtyId'
>;

const AddressField = ({ disabled }: Props) => {
  const { control, watch, setValue } = useArticleForm();
  const address = useController({ control, name: 'address' });
  const fullAddress = useController({ control, name: 'fullAddress' });
  const coordinate = useController({ control, name: 'coordinate' });
  const corRealtyId = useController({ control, name: 'corRealtyId' });

  const [isJusoDialogOpen, setIsJusoDialogOpen] = useState(false);

  const handleSelectAddress = (addressForm: AddressFormState) => {
    logger.track('select_address', {
      address_form: addressForm,
    });

    address.field.onChange(addressForm.address);
    fullAddress.field.onChange(addressForm.fullAddress);
    coordinate.field.onChange(addressForm.coordinate);
    corRealtyId.field.onChange(addressForm.corRealtyId);

    if (addressForm.fullAddress) {
      getBuildingLedger(addressForm.fullAddress).then((ledger) => {
        if (ledger) {
          ledger.buildingUsage &&
            setValue(
              'buildingUsage',
              (ledger.buildingUsage as BuildingUsageEnum) ?? watch('buildingUsage')
            );
          ledger.area && setValue('area', ledger.area.toString() ?? watch('area'));
          ledger.buildingApprovalDate &&
            setValue('buildingApprovalDate', ledger.buildingApprovalDate);
          ledger.elevator &&
            ledger.elevator > 0 &&
            setValue(
              'options',
              unionBy(
                watch('options') ?? [],
                [{ name: ArticleOptionNameEnum.Elevator, value: ArticleOptionValueEnum.Yes }],
                'name'
              )
            );
          ledger.floor && setValue('floor', ledger.floor.toString() ?? watch('floor'));
          ledger.topFloor && setValue('topFloor', ledger.topFloor.toString() ?? watch('topFloor'));
        }
      });
    }
  };

  const fullAddressValue = watch('fullAddress');

  return (
    <div className="flex flex-col gap-1.5">
      <FieldLogger name="address" eventParams={{}}>
        <TextField
          {...address.field}
          value={address.field.value}
          onChange={() => {}}
          readOnly
          disabled={disabled}
          label="매물 주소"
          placeholder="주소를 입력하세요"
          width="100%"
          invalid={!!address.fieldState.error}
          errorMessage={address.fieldState.error?.message}
          onClick={() => setIsJusoDialogOpen(true)}
        />
      </FieldLogger>
      {fullAddressValue && (
        <div className="text-fg-neutral bg-bg-neutral body-small-default flex flex-col gap-1 rounded-md px-4 py-3">
          <div>{fullAddressValue}</div>
        </div>
      )}
      <SearchJusoDialog
        isOpen={isJusoDialogOpen}
        onOpenChange={setIsJusoDialogOpen}
        onComplete={handleSelectAddress}
      />
    </div>
  );
};

export default AddressField;
