import type { mainQuery$data } from '@/__generated__/mainQuery.graphql';
import { STAGE, APP_VERSION, config } from '@/config';
import { LoggerEventParams, LoggerEventProperties, LoggerEventType } from '@/logger/eventSchema';
import { filterNil } from '@/utils/misc';
import * as amplitude from '@amplitude/analytics-browser';

class Logger {
  init() {
    amplitude.init(config.amplitudeKey, {
      minIdLength: 1,
      defaultTracking: true,
      logLevel:
        STAGE !== 'production' ? amplitude.Types.LogLevel.Debug : amplitude.Types.LogLevel.Warn,
      appVersion: APP_VERSION,
    });
  }
  setUser(user: {
    id: string;
    isDebugUser: boolean;
    bizProfileBroker?: Exclude<mainQuery$data['viewer'], null | undefined>['bizProfileBroker'];
  }) {
    const identifyEvent = new amplitude.Identify();

    amplitude.setUserId(String(user.id));
    identifyEvent.set('is_debug_user', user.isDebugUser);
    user.bizProfileBroker &&
      identifyEvent.set('biz_profile_broker', filterNil(user.bizProfileBroker));
    amplitude.identify(identifyEvent);
  }

  track = <TEventName extends LoggerEventType>(
    name: TEventName,
    params: LoggerEventParams<TEventName>
  ) => {
    const event = LoggerEventProperties[name];

    amplitude.logEvent(`[${event.type}] ${name}`, { ...params });
  };
}

const logger = new Logger();

export default logger;
