import { createFileRoute, Outlet, redirect, useRouter } from '@tanstack/react-router';
import { QRCode } from 'react-qrcode-logo';
import { useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import PageError from '@/components/PageError';
import logger from '@/utils/Logger';
import { captureException } from '@sentry/react';
import { createMinikarrotScheme } from '@daangn/webview-link-router';
import { config, STAGE } from '@/config';
import MainEnvironment from '@/relay/environment';
import type { mainQuery } from '@/__generated__/mainQuery.graphql';

export const Route = createFileRoute('/_protected')({
  beforeLoad: async ({ search, context }) => {
    const result = await context.appQuery.observable.toPromise();

    if (!result?.viewer) {
      throw redirect({
        to: '/auth/login',
        search,
      });
    }

    if (!result.viewer.bizProfileBroker) {
      const permissionError = new Error('Not verified business profile');
      permissionError.name = 'BusinessProfileError';
      throw permissionError;
    }
  },
  component: Page,
  onError: (error) => {
    captureException(error);
  },
  errorComponent: (props) => {
    const error = props.error as Error;

    if (error.name === 'BusinessProfileError') {
      return <BusinessProfileErrorComponent />;
    }

    return <PageError />;
  },
});

function Page() {
  return <Outlet />;
}

function BusinessProfileErrorComponent() {
  const appScheme = createMinikarrotScheme({
    url: `${config.webviewUrl}/biz_profile/create`,
    stage: STAGE,
  });
  const router = useRouter();

  useEffect(() => {
    logger.track('show_biz_profile_error', {});

    const interval = setInterval(() => {
      fetchQuery<mainQuery>(MainEnvironment, router.options.context.appQuery.node, {})
        .toPromise()
        .then((data) => {
          if (data?.viewer?.bizProfileBroker) {
            window.location.reload();
          }
        })
        .catch(() => {});
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex h-full items-center justify-center text-center">
      <div className="space-y-8">
        <div className="space-y-2">
          <h1 className="heading-xlarge">비즈프로필 가입이 필요해요</h1>
          <h3 className="body-large-default">아래의 QR 코드를 통해 바로 가입할 수 있어요</h3>
        </div>
        <div className="bg-bg-layerDefaultHover p-[60px]">
          <QRCode
            value={appScheme}
            size={248}
            logoImage="https://assetstorage.krrt.io/1138369647032855545/ba118f7e-06c5-4d72-b10d-9a64641e7ae5/width=65.7,height=112.3.webp"
            logoWidth={24}
            logoHeight={40}
          />
        </div>
      </div>
    </div>
  );
}
