import { fetchQuery, graphql } from 'relay-runtime';
import MainEnvironment from '@/relay/environment';
import type { getBuildingLedgerQuery } from '@/__generated__/getBuildingLedgerQuery.graphql';

export const getBuildingLedger = async (address: string) => {
  const response = await fetchQuery<getBuildingLedgerQuery>(
    MainEnvironment,
    graphql`
      query getBuildingLedgerQuery($address: String!) {
        getBuildingLedger(input: { address: $address }) {
          area
          buildingUsage
          buildingApprovalDate
          elevator
          floor
          topFloor
        }
      }
    `,
    { address }
  )
    .toPromise()
    .catch(() => null);

  return response?.getBuildingLedger ?? null;
};
