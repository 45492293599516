/**
 * @generated SignedSource<<fb66c054bfbde9097cc54f06e3151249>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BuildingUsageEnum = "ACCOMMODATION_FACILITY" | "BROADCASTING_AND_COMMUNICATION_FACILITY" | "CAR_RELATED_FACILITY" | "CEMETERY_RELATED_FACILITY" | "CORRECTION_AND_MILITARY_FACILITY" | "CULTURAL_AND_ASSEMBLY_FACILITIES" | "DOOR_AND_PLANT_RELATED_FACILITY" | "EDUCATION_AND_RESEARCH_FACILITY" | "ELDERLY_FACILITY" | "EXERCISE_FACILITY" | "FACTORY" | "FUNERAL_HOME" | "HAZARDOUS_MATERIAL_FACILITY" | "MEDICAL_FACILITY" | "OFFICE_FACILITY" | "POWER_GENERATION_FACILITY" | "PUBLIC_HOUSING" | "RECREATION_FACILITY" | "RELIGIOUS_FACILITY" | "SALE_FACILITY" | "SEWAGE_AND_WASTE_PROCESSING_FACILITY" | "SINGLE_FAMILY_HOUSING" | "STUDY_FACILITY" | "TEMPORARY_BUILDING" | "TOURISM_AND_RECREATION_FACILITY" | "TRANSPORTATION_FACILITY" | "TYPE_1_NEIGHBORHOOD_LIVING_FACILITY" | "TYPE_2_NEIGHBORHOOD_LIVING_FACILITY" | "WAREHOUSE_FACILITY";
export type getBuildingLedgerQuery$variables = {
  address: string;
};
export type getBuildingLedgerQuery$data = {
  readonly getBuildingLedger: {
    readonly area: number | null;
    readonly buildingApprovalDate: string | null;
    readonly buildingUsage: BuildingUsageEnum | null;
    readonly elevator: number | null;
    readonly floor: number | null;
    readonly topFloor: number | null;
  } | null;
};
export type getBuildingLedgerQuery = {
  response: getBuildingLedgerQuery$data;
  variables: getBuildingLedgerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "address",
            "variableName": "address"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "BuildingLedger",
    "kind": "LinkedField",
    "name": "getBuildingLedger",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "area",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buildingUsage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buildingApprovalDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "elevator",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "floor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "topFloor",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "getBuildingLedgerQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "getBuildingLedgerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "820a7ddfe03c74ea2a4126509dc05f87",
    "id": null,
    "metadata": {},
    "name": "getBuildingLedgerQuery",
    "operationKind": "query",
    "text": "query getBuildingLedgerQuery(\n  $address: String!\n) {\n  getBuildingLedger(input: {address: $address}) {\n    area\n    buildingUsage\n    buildingApprovalDate\n    elevator\n    floor\n    topFloor\n  }\n}\n"
  }
};
})();

(node as any).hash = "69b6982aeca5a69d83a0b7a1caa7658a";

export default node;
