import FieldLogger from '@/routes/_protected/articles/-components/FieldLogger';
import { useArticleForm } from '@/routes/_protected/articles/-contexts';
import { MAX_ADDRESS_INFO_LENGTH } from '@/scheme/article';
import { replaceEmoji } from '@/utils/validate';
import { TextField } from '@daangn/carotene';
import { useController } from 'react-hook-form';

const AddressInfoField = () => {
  const { control } = useArticleForm();
  const { field, fieldState } = useController({ control, name: 'addressInfo' });

  return (
    <FieldLogger name="address_info" eventParams={{}}>
      <TextField
        {...field}
        value={field.value ?? undefined}
        onChange={(e) => {
          field.onChange(replaceEmoji(e.target.value));
        }}
        maxLength={MAX_ADDRESS_INFO_LENGTH}
        width="100%"
        label="위치 한줄 설명"
        placeholder="예) OO역 5분 거리"
        description="매물의 위치와 관련된 한 줄 설명만 작성할 수 있어요."
        invalid={!!fieldState.error}
        errorMessage={fieldState.error?.message}
        data-log-id="address-info-field"
      />
    </FieldLogger>
  );
};

export default AddressInfoField;
