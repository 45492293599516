import { Dialog, DialogContent, DialogHeader, DialogTitle, ProgressCircle } from '@daangn/carotene';
import SearchJusoAddress, {
  type JusoAddressForm,
} from '@/routes/_protected/articles/-components/SearchJusoAddress';
import { useState } from 'react';
import toast from 'react-hot-toast';
import type { Coordinate } from '@/types/map';
import { parseAddressDetail } from '@daangn/realty-sdk';

type Props = {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onComplete: (address: { address: string; fullAddress: string; coordinate: Coordinate }) => void;
};

const SearchJusoDialog = ({ isOpen, onOpenChange, onComplete }: Props) => {
  const [isFetchingUnit, setIsFetchingUnit] = useState(false);

  const handleOnComplete = (jusoAddress: JusoAddressForm) => {
    const { baseAddress, unit } = jusoAddress;

    if (!unit) {
      toast.error('동, 호수를 입력해주세요.');
      return;
    }

    const { eupmyeondongName, leeName, ji, bun } = parseAddressDetail({
      address: baseAddress.regionAddress,
    });
    const jiBunAddress = [ji, bun].filter(Boolean).join('-');
    const subAddress = [leeName || eupmyeondongName, jiBunAddress].filter(Boolean).join(' ');
    const fullAddress = [
      baseAddress.roadAddress,
      unit.dongNm,
      unit.floorNm,
      unit.hoNm,
      `[${subAddress}]`,
    ]
      .filter(Boolean)
      .join(' ');

    onComplete({
      address: baseAddress.roadAddress,
      fullAddress,
      coordinate: baseAddress.coordinate,
    });
    onOpenChange(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="flex min-h-[388px] w-[560px] flex-col">
        <DialogHeader className="border-b-1 border-stroke-decorative border-solid px-5">
          <div className="flex items-center justify-between">
            <DialogTitle>주소</DialogTitle>
          </div>
        </DialogHeader>

        <div className="p-5">
          <SearchJusoAddress onComplete={handleOnComplete} setIsFetchingUnit={setIsFetchingUnit} />
        </div>
        {isFetchingUnit && (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex gap-1.5">
              <ProgressCircle size={24} />
              <span className="body-medium-strong">주소 정보를 불러오고 있어요</span>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SearchJusoDialog;
