const RealtyHomeBanner = () => {
  return (
    <div className="bg-bg-layerDefaultHover flex items-center justify-center gap-3 rounded-lg pt-8">
      <div className="px-4">
        <img
          src="https://assetstorage.krrt.io/1138369647032855545/d460f7e2-aadf-4b0a-b540-c1bcb5cb3763/width=622,height=302.png"
          width={311}
        />
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="text-xl font-bold">작성한 매물은 당근부동산에서 볼 수 있어요</h3>
        <p className="text-fg-neutralMuted">당근 홈 최상단에서 접속할 수 있어요.</p>
      </div>
    </div>
  );
};

export default RealtyHomeBanner;
