import { IconXmarkCircleFill } from '@daangn/react-monochrome-icon';
import classNames from 'classnames';
import type { ButtonHTMLAttributes } from 'react';

type Props = ButtonHTMLAttributes<HTMLButtonElement>;

const DeleteButton = (props: Props) => {
  return (
    <button {...props} className={classNames('text-fg-neutral bg-transparent', props.className)}>
      <IconXmarkCircleFill width={28} height={28} />
    </button>
  );
};

export default DeleteButton;
