import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { memo, type ReactNode } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Dialog } from '../Dialog';
import { VisuallyHidden } from '@daangn/carotene';

type Props = {
  url: string;
  title: string;
  description: ReactNode;
  isOpen: boolean;
  close: () => void;
};

const QRDialog = ({ url, title, description, isOpen, close }: Props) => {
  const handleOnOpenChange = (open: boolean) => {
    if (!open) close();
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOnOpenChange}>
      <Dialog.Overlay />

      <Dialog.Content className="w-[560px] !px-0 !pt-0 pb-10">
        <VisuallyHidden>
          <Dialog.Title />
          <Dialog.Description />
        </VisuallyHidden>

        <div className="flex justify-between px-6 py-5">
          <div className="heading-medium">{title}</div>

          <Dialog.Close>
            <IconXmarkLine width={24} height={24} />
          </Dialog.Close>
        </div>

        <div className="bg-gray100 flex w-full items-center justify-center py-[41px]">
          <QRCode
            value={url}
            size={160}
            logoImage="https://assetstorage.krrt.io/1138369647032855545/ba118f7e-06c5-4d72-b10d-9a64641e7ae5/width=65.7,height=112.3.webp"
            logoWidth={24}
            logoHeight={40}
            quietZone={9}
          />
        </div>

        <div className="body-medium-default text-fg-neutral mt-4 text-center">{description}</div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default memo(QRDialog);
