import type {
  useSearchJusoAddressResult_query$data,
  useSearchJusoAddressResult_query$key,
} from '__generated__/useSearchJusoAddressResult_query.graphql';
import { useSearchJusoAddressResultQuery } from '__generated__/useSearchJusoAddressResultQuery.graphql';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';

export type JusoAddressNode =
  useSearchJusoAddressResult_query$data['searchAddress']['edges'][number]['node'];

const useSearchJusoAddress = ({ query }: { query: string }) => {
  const queryRef = useLazyLoadQuery<useSearchJusoAddressResultQuery>(
    graphql`
      query useSearchJusoAddressResultQuery(
        $cursor: String
        $count: Int
        $input: SearchAddressesDataInput!
      ) {
        ...useSearchJusoAddressResult_query
      }
    `,
    {
      count: 20,
      input: {
        query: query,
      },
    }
  );

  const result = usePaginationFragment<
    useSearchJusoAddressResultQuery,
    useSearchJusoAddressResult_query$key
  >(
    graphql`
      fragment useSearchJusoAddressResult_query on Query
      @refetchable(queryName: "useSearchJusoAddressResultRefetchQuery") {
        searchAddress(first: $count, after: $cursor, data: $input)
          @connection(key: "useSearchJusoAddressResult_searchAddress") {
          edges {
            node {
              zipNo
              regionAddress
              roadAddress
              buildingName
              roadName
              admCd
              buldMnnm
              buldSlno
              rnMgtSn
              udrtYn
            }
          }
        }
      }
    `,
    queryRef
  );

  return result;
};

export default useSearchJusoAddress;
