import ArticleItemSkeleton from './ArticleItemSkeleton';

const ArticleListSkeleton = () => {
  return (
    <div className="grid w-full grid-cols-2 gap-8">
      {[...Array(10)].map((_, i) => (
        <ArticleItemSkeleton key={i} />
      ))}
    </div>
  );
};

export default ArticleListSkeleton;
