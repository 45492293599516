import {
  CommonRegex,
  billionConvert,
  checkCanMoveInNow,
  format,
  formatDate,
  isSameYear,
  ko_default,
  loadScript,
  parse
} from "./chunk-FPXTU4MB.mjs";
import {
  KakaoAPI
} from "./chunk-OI6ZH4RB.mjs";
import {
  __publicField
} from "./chunk-QZ7TP4HQ.mjs";

// src/services/address/utils.ts
var parseAddress = (address) => {
  var _a, _b;
  const validAddress = address.replace(/\s\s+/g, " ").replace(CommonRegex.invalidAddress, " ").replace(/\s([0-9-]+번?)길([0-9-]+)\s/g, (_, p1, p2) => ` ${p1}\uAE38 ${p2} `).replace(/([0-9-]+)(\(\S+\))/g, (_, p1, p2) => `${p1} ${p2}`);
  let addressOnly = validAddress;
  if (validAddress.match(CommonRegex.roadCorToAddress)) {
    addressOnly = validAddress.replace(CommonRegex.roadCorToAddress, (_, p1) => p1);
  } else if (validAddress.match(CommonRegex.corToAddress)) {
    addressOnly = validAddress.replace(CommonRegex.corToAddress, (_, p1) => p1);
  }
  addressOnly = addressOnly.trim();
  const remainAddress = validAddress.replace(addressOnly, "");
  const possiblyBuildingName = (_b = (_a = remainAddress.match(CommonRegex.BeforeDetailAddress)) == null ? void 0 : _a[1]) == null ? void 0 : _b.trim();
  const addressWithBuilding = [addressOnly, possiblyBuildingName].filter(Boolean).join(" ");
  return {
    addressOnly,
    addressWithBuilding,
    possiblyBuildingName
  };
};
var CoordinateFinder = class {
  constructor({ kakaoMapKey, googleMapKey }) {
    __publicField(this, "coordinateFinders");
    __publicField(this, "findByAddress", async (address) => {
      for (const finder of this.coordinateFinders) {
        try {
          const result = await finder(address);
          if (result.length > 0) {
            return result;
          }
        } catch (e) {
        }
      }
      return [];
    });
    const kakaoAPI = new KakaoAPI(kakaoMapKey);
    this.coordinateFinders = [
      async (address) => {
        let coordInfo = [];
        const data = await kakaoAPI.searchAddress({
          query: address,
          page: 1,
          size: 1,
          analyze_type: "exact"
        });
        if (data.documents[0]) coordInfo = [data.documents[0].x, data.documents[0].y];
        return coordInfo;
      },
      async (address) => {
        await loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleMapKey}`);
        return new google.maps.Geocoder().geocode({ address }).then(({ results }) => {
          if (results == null ? void 0 : results[0]) {
            const { lat, lng } = results[0].geometry.location;
            return [String(lng()), String(lat())];
          }
          return [];
        });
      }
    ];
  }
};
var parseAddressDetail = ({ address }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const eupmyeonMatched = (_a = address.match(/([^ []+읍|[^ []+면)/)) == null ? void 0 : _a[0];
  const dongleeMatched = (_b = address.match(
    /([^ []+동|[^ []+리|[^ []+가)(?= \d+-?\d*([\]|\s외])?|$)/
  )) == null ? void 0 : _b[0];
  const match = address.match(/(동 |리 |가 )(\d+-?\d*)([\]|\s외]|$)/);
  const bunjiMatched = match && match[2] ? match[2] : "";
  const bunjiSplited = bunjiMatched.split("-");
  const parsedAddressNames = {
    sigunguName: (_d = (_c = address.match(/[가-힣]+[도시군구]\s(?:[가-힣]+[시군구]\s)?(?:[가-힣]+[시군구]\s)?/g)) == null ? void 0 : _c[0].trim()) != null ? _d : "",
    eupmyeondongName: (_e = eupmyeonMatched != null ? eupmyeonMatched : dongleeMatched) != null ? _e : "",
    leeName: eupmyeonMatched ? dongleeMatched != null ? dongleeMatched : "" : "",
    bun: (_f = bunjiSplited[0]) != null ? _f : "",
    ji: (_g = bunjiSplited[1]) != null ? _g : ""
  };
  return parsedAddressNames;
};
var getJibunAddress = (address) => {
  const { sigunguName, eupmyeondongName, leeName, bun, ji } = parseAddressDetail({
    address
  });
  const mainAddress = [sigunguName, eupmyeondongName, leeName].filter(Boolean).join(" ");
  const detailAddress = [bun, ji].filter(Boolean).join("-");
  return [mainAddress, detailAddress].filter(Boolean).join(" ");
};
var removeDetailFromAddress = (address) => {
  const parsedAddress = address.replace(/제(\d+(동|호|층))/g, "$1").replace(/\[.+\]$/g, "");
  return parsedAddress;
};
var checkIsDagaguRentalHouse = ({
  addressKind,
  isRentalHouse
}) => {
  return addressKind === "BUILDING" && isRentalHouse;
};

// src/services/address/kakao.ts
var KakaoCategory = /* @__PURE__ */ ((KakaoCategory2) => {
  KakaoCategory2["LargeMarket"] = "MT1";
  KakaoCategory2["ConvenienceStore"] = "CS2";
  KakaoCategory2["NurseryAndKindergarten"] = "PS3";
  KakaoCategory2["School"] = "SC4";
  KakaoCategory2["Academy"] = "AC5";
  KakaoCategory2["ParkingLot"] = "PK6";
  KakaoCategory2["FuelAndChargingStation"] = "OL7";
  KakaoCategory2["SubwayStation"] = "SW8";
  KakaoCategory2["Bank"] = "BK9";
  KakaoCategory2["CulturalFacility"] = "CT1";
  KakaoCategory2["Brokerage"] = "AG2";
  KakaoCategory2["PublicOffice"] = "PO3";
  KakaoCategory2["TouristAttraction"] = "AT4";
  KakaoCategory2["Accommodation"] = "AD5";
  KakaoCategory2["Restaurant"] = "FD6";
  KakaoCategory2["Cafe"] = "CE7";
  KakaoCategory2["Hospital"] = "HP8";
  KakaoCategory2["Pharmacy"] = "PM9";
  return KakaoCategory2;
})(KakaoCategory || {});

// src/services/article/constants.ts
var SEMI_BASEMENT_FLOOR = "0.5";
var \uD3C9\uB2F9_\uC81C\uACF1\uBBF8\uD130 = 3.305785;
var ArticleUploadType = /* @__PURE__ */ ((ArticleUploadType2) => {
  ArticleUploadType2["Direct"] = "direct";
  ArticleUploadType2["Broker"] = "broker";
  return ArticleUploadType2;
})(ArticleUploadType || {});
var TradeTypes = ["YEAR", "MONTH", "BORROW", "BUY", "SHORT"];
var MonthlyPayableTradeTypes = ["MONTH", "YEAR", "SHORT"];
var AdjustableTradeTypes = ["MONTH", "YEAR"];
var ArticleStatuses = ["ON_GOING", "RESERVED", "TRADED"];
var OneRoomSalesTypes = ["OPEN_ONE_ROOM", "SPLIT_ONE_ROOM"];
var ResidentSalesTypes = [
  ...OneRoomSalesTypes,
  "APART",
  "TWO_ROOM",
  "OFFICETEL"
];
var BuildingNameAvailableSalesTypes = ["APART", "OFFICETEL"];
var SupplyAreaFirstSalesTypes = ["APART", "STORE"];
var ArticleOptionValues = ["DONT_KNOW", "YES", "NO"];
var RequiredArticleOptionNames = [
  "MORTGAGE",
  "PET",
  "PARKING"
];
var EtcArticleOptionNames = [
  "ELEVATOR",
  "LOFT",
  "ROOFTOP",
  "WASHER",
  "FRIDGE",
  "AIRCON",
  "ELEC_RANGE",
  "GAS_RANGE",
  "INDUCTION",
  "BED"
];
var WriterTypes = ["TENANT", "LESSOR"];
var MainBuildingUsageTypes = [
  "PUBLIC_HOUSING",
  "SINGLE_FAMILY_HOUSING",
  "TYPE_1_NEIGHBORHOOD_LIVING_FACILITY",
  "TYPE_2_NEIGHBORHOOD_LIVING_FACILITY",
  "OFFICE_FACILITY",
  "ACCOMMODATION_FACILITY",
  "CULTURAL_AND_ASSEMBLY_FACILITIES"
];
var EtcBuildingUsageTypes = [
  "RELIGIOUS_FACILITY",
  "SALE_FACILITY",
  "TRANSPORTATION_FACILITY",
  "MEDICAL_FACILITY",
  "EDUCATION_AND_RESEARCH_FACILITY",
  "ELDERLY_FACILITY",
  "STUDY_FACILITY",
  "EXERCISE_FACILITY",
  "RECREATION_FACILITY",
  "FACTORY",
  "WAREHOUSE_FACILITY",
  "TEMPORARY_BUILDING",
  "SEWAGE_AND_WASTE_PROCESSING_FACILITY",
  "CORRECTION_AND_MILITARY_FACILITY",
  "BROADCASTING_AND_COMMUNICATION_FACILITY",
  "POWER_GENERATION_FACILITY",
  "CEMETERY_RELATED_FACILITY",
  "TOURISM_AND_RECREATION_FACILITY",
  "FUNERAL_HOME",
  "CAR_RELATED_FACILITY",
  "HAZARDOUS_MATERIAL_FACILITY",
  "DOOR_AND_PLANT_RELATED_FACILITY"
];
var BuildingUsageTypes = [
  ...MainBuildingUsageTypes,
  ...EtcBuildingUsageTypes
];
var ManageCostOptions = [
  "WATERWORKS",
  "ELECTRIC",
  "INTERNET",
  "GAS",
  "CLEANING",
  "TV",
  "PARKING",
  "BOILER",
  "ELEVATOR"
];
var ManageCostOptionText = {
  WATERWORKS: "\uC218\uB3C4\uB8CC",
  ELECTRIC: "\uC804\uAE30\uB8CC",
  INTERNET: "\uC778\uD130\uB137\uBE44",
  GAS: "\uAC00\uC2A4\uBE44",
  CLEANING: "\uCCAD\uC18C\uBE44",
  TV: "\uC720\uC120 TV",
  PARKING: "\uC8FC\uCC28\uBE44",
  BOILER: "\uB09C\uBC29\uBE44",
  ELEVATOR: "\uC2B9\uAC15\uAE30 \uC720\uC9C0\uBE44"
};

// src/services/location/constants.ts
var \uC81C\uC8FC\uB3C4_\uAD6C\uBD84_\uC704\uB3C4 = 34;

// src/internal/utils.ts
var truthy = (value) => {
  return !!value;
};

// ../../node_modules/@toss/hangul/esm/constants.mjs
var COMPLETE_HANGUL_START_CHARCODE = "\uAC00".charCodeAt(0);
var COMPLETE_HANGUL_END_CHARCODE = "\uD7A3".charCodeAt(0);
var DISASSEMBLED_CONSONANTS_BY_CONSONANT = {
  // 종성이 없는 경우 '빈' 초성으로 관리하는 것이 편리하여, 빈 문자열도 포함한다.
  "": "",
  \u3131: "\u3131",
  \u3132: "\u3132",
  \u3133: "\u3131\u3145",
  \u3134: "\u3134",
  \u3135: "\u3134\u3148",
  \u3136: "\u3134\u314E",
  \u3137: "\u3137",
  \u3138: "\u3138",
  \u3139: "\u3139",
  \u313A: "\u3139\u3131",
  \u313B: "\u3139\u3141",
  \u313C: "\u3139\u3142",
  \u313D: "\u3139\u3145",
  \u313E: "\u3139\u314C",
  \u313F: "\u3139\u314D",
  \u3140: "\u3139\u314E",
  \u3141: "\u3141",
  \u3142: "\u3142",
  \u3143: "\u3143",
  \u3144: "\u3142\u3145",
  \u3145: "\u3145",
  \u3146: "\u3146",
  \u3147: "\u3147",
  \u3148: "\u3148",
  \u3149: "\u3149",
  \u314A: "\u314A",
  \u314B: "\u314B",
  \u314C: "\u314C",
  \u314D: "\u314D",
  \u314E: "\u314E"
};
var DISASSEMBLED_VOWELS_BY_VOWEL = {
  \u314F: "\u314F",
  \u3150: "\u3150",
  \u3151: "\u3151",
  \u3152: "\u3152",
  \u3153: "\u3153",
  \u3154: "\u3154",
  \u3155: "\u3155",
  \u3156: "\u3156",
  \u3157: "\u3157",
  \u3158: "\u3157\u314F",
  \u3159: "\u3157\u3150",
  \u315A: "\u3157\u3163",
  \u315B: "\u315B",
  \u315C: "\u315C",
  \u315D: "\u315C\u3153",
  \u315E: "\u315C\u3154",
  \u315F: "\u315C\u3163",
  \u3160: "\u3160",
  \u3161: "\u3161",
  \u3162: "\u3161\u3163",
  \u3163: "\u3163"
};
var HANGUL_CHARACTERS_BY_FIRST_INDEX = ["\u3131", "\u3132", "\u3134", "\u3137", "\u3138", "\u3139", "\u3141", "\u3142", "\u3143", "\u3145", "\u3146", "\u3147", "\u3148", "\u3149", "\u314A", "\u314B", "\u314C", "\u314D", "\u314E"];
var HANGUL_CHARACTERS_BY_MIDDLE_INDEX = Object.values(DISASSEMBLED_VOWELS_BY_VOWEL);
var HANGUL_CHARACTERS_BY_LAST_INDEX = ["", "\u3131", "\u3132", "\u3133", "\u3134", "\u3135", "\u3136", "\u3137", "\u3139", "\u313A", "\u313B", "\u313C", "\u313D", "\u313E", "\u313F", "\u3140", "\u3141", "\u3142", "\u3144", "\u3145", "\u3146", "\u3147", "\u3148", "\u314A", "\u314B", "\u314C", "\u314D", "\u314E"].map(function(consonant) {
  return DISASSEMBLED_CONSONANTS_BY_CONSONANT[consonant];
});

// ../../node_modules/@toss/hangul/esm/disassembleCompleteHangulCharacter.mjs
function disassembleCompleteHangulCharacter(letter) {
  var charCode = letter.charCodeAt(0);
  var isCompleteHangul = COMPLETE_HANGUL_START_CHARCODE <= charCode && charCode <= COMPLETE_HANGUL_END_CHARCODE;
  if (!isCompleteHangul) {
    return void 0;
  }
  var hangulCode = charCode - COMPLETE_HANGUL_START_CHARCODE;
  var lastIndex = hangulCode % 28;
  var middleIndex = (hangulCode - lastIndex) / 28 % 21;
  var firstIndex = Math.floor((hangulCode - lastIndex) / 28 / 21);
  return {
    first: HANGUL_CHARACTERS_BY_FIRST_INDEX[firstIndex],
    middle: HANGUL_CHARACTERS_BY_MIDDLE_INDEX[middleIndex],
    last: HANGUL_CHARACTERS_BY_LAST_INDEX[lastIndex]
  };
}

// ../../node_modules/@toss/hangul/esm/utils.mjs
function hasBatchim(str) {
  var lastChar = str[str.length - 1];
  var disassembled = disassembleCompleteHangulCharacter(lastChar);
  return disassembled != null && disassembled.last !== "";
}

// ../../node_modules/@toss/hangul/esm/josa.mjs
var \uB85C_\uC870\uC0AC = ["\uC73C\uB85C/\uB85C", "\uC73C\uB85C\uC11C/\uB85C\uC11C", "\uC73C\uB85C\uC368/\uB85C\uC368", "\uC73C\uB85C\uBD80\uD130/\uB85C\uBD80\uD130"];
function josa(word, josa2) {
  if (word.length === 0) {
    return word;
  }
  return word + josaPicker(word, josa2);
}
josa.pick = josaPicker;
function josaPicker(word, josa2) {
  var _disassembleCompleteH;
  var has\uBC1B\uCE68 = hasBatchim(word);
  var index = has\uBC1B\uCE68 ? 0 : 1;
  var is\uC885\uC131\u3139 = ((_disassembleCompleteH = disassembleCompleteHangulCharacter(word[word.length - 1])) === null || _disassembleCompleteH === void 0 ? void 0 : _disassembleCompleteH.last) === "\u3139";
  var isCaseOf\uB85C = has\uBC1B\uCE68 && is\uC885\uC131\u3139 && \uB85C_\uC870\uC0AC.includes(josa2);
  if (josa2 === "\uC640/\uACFC" || isCaseOf\uB85C) {
    index = index === 0 ? 1 : 0;
  }
  var isEndsWith\uC774 = word[word.length - 1] === "\uC774";
  if (josa2 === "\uC774\uC5D0\uC694/\uC608\uC694" && isEndsWith\uC774) {
    index = 1;
  }
  return josa2.split("/")[index];
}

// src/services/article/utils.ts
var getArticleMainTradeType = (articleTradeTypes, preferredTradeType, tradeTypeFilters = ["BORROW", "BUY", "MONTH", "SHORT", "YEAR"]) => {
  if (articleTradeTypes.length === 1) {
    return articleTradeTypes[0];
  }
  const filteredTradeTypes = articleTradeTypes.filter(
    (articleTradeType) => tradeTypeFilters.includes(articleTradeType)
  );
  if (filteredTradeTypes.length === 1) {
    return filteredTradeTypes[0];
  }
  if (filteredTradeTypes.length === 0) {
    return preferredTradeType != null ? preferredTradeType : articleTradeTypes[0];
  }
  const preferredFilteredTradeType = filteredTradeTypes.find(
    (tradeType) => tradeType === preferredTradeType
  );
  return preferredFilteredTradeType != null ? preferredFilteredTradeType : filteredTradeTypes[0];
};
var isJejuArticle = (article) => {
  if (article == null ? void 0 : article.address.includes("\uC81C\uC8FC")) return true;
  if (!!(article == null ? void 0 : article.coordinate.lat) && Number(article == null ? void 0 : article.coordinate.lat) < \uC81C\uC8FC\uB3C4_\uAD6C\uBD84_\uC704\uB3C4) return true;
  return false;
};
var isOneOfSalesType = (currentSalesType, salesTypes) => {
  return !!currentSalesType && [salesTypes].flat().includes(currentSalesType);
};
var getArticleSubSalesTypeText = (salesType) => {
  switch (salesType) {
    case "OPEN_ONE_ROOM":
      return "\uC624\uD508\uD615";
    case "SPLIT_ONE_ROOM":
      return "\uBD84\uB9AC\uD615";
    default:
      return "";
  }
};
var getArticleSalesTypeText = (salesType, option) => {
  const { simple = false, subText = false, etcText } = option || {};
  const subTypeText = subText ? getArticleSubSalesTypeText(salesType) : "";
  const getMainText = () => {
    switch (salesType) {
      case "OPEN_ONE_ROOM":
      case "SPLIT_ONE_ROOM":
        return "\uC6D0\uB8F8";
      case "TWO_ROOM":
        return simple ? "\uD22C\uB8F8 \uC774\uC0C1" : "\uBE4C\uB77C(\uD22C\uB8F8 \uC774\uC0C1)";
      case "APART":
        return "\uC544\uD30C\uD2B8";
      case "STORE":
        return "\uC0C1\uAC00";
      case "OFFICETEL":
        return "\uC624\uD53C\uC2A4\uD154";
      case "ETC":
        if (etcText) {
          return `\uAE30\uD0C0(${etcText})`;
        }
        return simple ? "\uAE30\uD0C0" : "\uAE30\uD0C0(\uC0AC\uBB34\uC2E4, \uC8FC\uD0DD, \uD1A0\uC9C0 \uB4F1)";
      default:
        "\uADF8 \uC678";
    }
  };
  return [subTypeText, getMainText()].filter(truthy).join(" ");
};
var SUBWAY_DISTANCE_METER_PER_MINUTE = 70;
var SalesTypeText = (salesType, options) => {
  switch (salesType) {
    case "ONE_ROOM":
      return "\uC6D0\uB8F8";
    case "TWO_ROOM":
      return (options == null ? void 0 : options.shorten) ? "\uD22C\uB8F8 \uC774\uC0C1" : "\uBE4C\uB77C(\uD22C\uB8F8 \uC774\uC0C1)";
    case "APART":
      return "\uC544\uD30C\uD2B8";
    case "STORE":
      return "\uC0C1\uAC00";
    case "OFFICETEL":
      return "\uC624\uD53C\uC2A4\uD154";
    case "ETC":
      return (options == null ? void 0 : options.shorten) ? "\uAE30\uD0C0" : "\uAE30\uD0C0(\uC0AC\uBB34\uC2E4, \uC8FC\uD0DD, \uD1A0\uC9C0 \uB4F1)";
    default:
      "\uADF8 \uC678";
  }
};
var getFilterSalesTypeByArticleSalesType = (salesType) => {
  if (salesType === "OPEN_ONE_ROOM" || salesType === "SPLIT_ONE_ROOM") {
    return "ONE_ROOM";
  }
  return salesType;
};
var getFloorTypeText = (floor) => {
  switch (floor) {
    case "SEMI_BASEMENT":
      return "\uBC18\uC9C0\uD558";
    case "FIRST":
      return "1\uCE35";
    case "SECOND_TO_FIFTH":
      return "2~5\uCE35";
    case "SIX_TO_NINTH":
      return "6~9\uCE35";
    case "TENTH_OR_MORE":
      return "10\uCE35 \uC774\uC0C1";
    // deprecated
    case "FIRST_TO_FORTH":
      return "1~4\uCE35";
    case "FIFTH_TO_NINTH":
      return "5~9\uCE35";
  }
};
var getArticleStatusText = (articleStatus) => {
  switch (articleStatus) {
    case "ON_GOING":
      return "\uD310\uB9E4\uC911";
    case "RESERVED":
      return "\uC608\uC57D\uC911";
    case "TRADED":
      return "\uAC70\uB798\uC644\uB8CC";
    default:
      return "\uC54C \uC218 \uC5C6\uC74C";
  }
};
var getArticleTradeTypeText = (articleTradeType) => {
  switch (articleTradeType) {
    case "SHORT":
      return "\uB2E8\uAE30";
    case "MONTH":
      return "\uC6D4\uC138";
    case "YEAR":
      return "\uC5F0\uC138";
    case "BORROW":
      return "\uC804\uC138";
    case "BUY":
      return "\uB9E4\uB9E4";
  }
};
var getArticleOptionNameText = (optionName, options) => {
  const { isBroker } = options || {};
  switch (optionName) {
    case "MORTGAGE":
      return "\uB300\uCD9C";
    case "PET":
      return "\uBC18\uB824\uB3D9\uBB3C";
    case "PARKING":
      return isBroker ? "\uC138\uB300\uB2F9 \uC8FC\uCC28" : "\uC8FC\uCC28";
    case "ELEVATOR":
      return "\uC5D8\uB9AC\uBCA0\uC774\uD130";
    case "LOFT":
      return "\uBCF5\uCE35";
    case "ROOFTOP":
      return "\uC625\uD0D1";
    case "WASHER":
      return "\uC138\uD0C1\uAE30";
    case "FRIDGE":
      return "\uB0C9\uC7A5\uACE0";
    case "AIRCON":
      return "\uC5D0\uC5B4\uCEE8";
    case "ELEC_RANGE":
      return "\uC804\uC790\uB80C\uC9C0";
    case "GAS_RANGE":
      return "\uAC00\uC2A4\uB80C\uC9C0";
    case "INDUCTION":
      return "\uC778\uB355\uC158";
    case "BED":
      return "\uCE68\uB300";
    default:
      return "";
  }
};
var getArticleOptionValueText = (articleOptionValue, exist = false) => {
  switch (articleOptionValue) {
    case "YES":
      return exist ? "\uC788\uC74C" : "\uAC00\uB2A5";
    case "NO":
      return exist ? "\uC5C6\uC74C" : "\uBD88\uAC00\uB2A5";
    case "DONT_KNOW":
      return "\uD655\uC778 \uD544\uC694";
    default:
      return "";
  }
};
var getArticleParkingOptionText = (articleOptionValue, availableParkingSpots) => {
  if (articleOptionValue !== "YES") {
    return getArticleOptionValueText(articleOptionValue);
  }
  return [availableParkingSpots && `${availableParkingSpots}\uB300`, "\uAC00\uB2A5"].filter(truthy).join(" ");
};
var getSaleTargetText = (tradeTypes) => {
  if (tradeTypes.includes("BUY")) {
    if (tradeTypes.length === 1) {
      return "\uAD6C\uB9E4\uC790";
    } else {
      return "\uC138\uC785\uC790 \uB610\uB294 \uAD6C\uB9E4\uC790";
    }
  } else {
    return "\uC138\uC785\uC790";
  }
};
var getWriterTypeText = (writerType) => {
  switch (writerType) {
    case "TENANT":
      return "\uC138\uC785\uC790";
    case "LESSOR":
      return "\uC9D1\uC8FC\uC778";
    case "BROKER":
      return "\uACF5\uC778\uC911\uAC1C\uC0AC";
    default:
      return "\uC54C \uC218 \uC5C6\uC74C";
  }
};
var getBuildingNameTitle = (salesType) => {
  if (salesType === "APART") {
    return "\uC544\uD30C\uD2B8\uBA85";
  }
  return "\uAC74\uBB3C\uBA85";
};
var getAreaText = (area) => {
  if (isStringNumberFalsy(area)) {
    return "\uD655\uC778 \uD544\uC694";
  }
  return [areaText(area || "", "\uD3C9"), areaText(area || "", "m\xB2")].filter(truthy).join(" \xB7 ");
};
var isStringNumberFalsy = (s) => {
  return !s || isNaN(Number(s)) || Number(s) === 0 || Number(s) === -0;
};
var getRoomText = ({
  roomCnt,
  bathroomCnt
}) => {
  if (!roomCnt && !bathroomCnt) {
    return "";
  }
  return [
    { label: "\uBC29", value: roomCnt },
    { label: "\uC695\uC2E4", value: bathroomCnt }
  ].filter(({ value }) => !!value).map(({ label, value }) => `${label} ${value}\uAC1C`).join(" / ");
};
var getFloorsText = ({
  floor,
  topFloor
}, option) => {
  const { simple = false } = option || {};
  if (!floor && !topFloor) {
    return "";
  }
  if (simple) {
    return floor ? getFloorText(floor) : "";
  }
  if (!floor && topFloor) {
    return `\uCD5C\uACE0\uCE35 ${getFloorText(topFloor)}`;
  }
  if (floor && !topFloor) {
    return getFloorText(floor);
  }
  return [floor, topFloor].filter(Boolean).map((value) => getFloorText(value || "0")).join(" / ");
};
var isSemiBasement = (floor) => floor === SEMI_BASEMENT_FLOOR;
var getMoveInDateText = (moveInDate, option) => {
  const { showImmediatelyText = true } = option || {};
  if (!moveInDate) {
    return "";
  }
  if (showImmediatelyText && checkCanMoveInNow(moveInDate)) {
    return "\uC989\uC2DC \uC785\uC8FC \uAC00\uB2A5";
  }
  const dateFormat = isSameYear(moveInDate, /* @__PURE__ */ new Date()) ? "MM/dd(eee) \uC774\uD6C4" : "yyyy/MM/dd(eee) \uC774\uD6C4";
  return format(new Date(moveInDate), dateFormat, {
    locale: ko_default
  });
};
var getManageCostPrice = (article) => {
  var _a;
  const { includeManageCostOptionV2, manageCost, etcManageCost, writerType } = article;
  if (writerType !== "BROKER") {
    return manageCost != null ? manageCost : 0;
  }
  const flatPriceOptions = (includeManageCostOptionV2 || []).filter((v) => v.payOption === "FIXED");
  const allPrice = (manageCost != null ? manageCost : 0) + (etcManageCost != null ? etcManageCost : 0) + ((_a = flatPriceOptions == null ? void 0 : flatPriceOptions.reduce((acc, curr) => {
    var _a2;
    return acc + ((_a2 = curr.fixedCost) != null ? _a2 : 0);
  }, 0)) != null ? _a : 0);
  return allPrice;
};
var getBrokerManageCostText = (article) => {
  const { includeManageCostOptionV2, isUnknownManageCost } = article;
  if (isUnknownManageCost) {
    return "\uD655\uC778 \uD544\uC694";
  }
  const actualPriceOptions = (includeManageCostOptionV2 || []).filter(
    (v) => v.payOption === "USED"
  );
  const flatPriceOptions = (includeManageCostOptionV2 || []).filter((v) => v.payOption === "FIXED");
  const price = getManageCostPrice(article);
  const actualPriceList = actualPriceOptions.map((v) => ManageCostOptionText[v.option]);
  const flatPriceList = flatPriceOptions.map((v) => ManageCostOptionText[v.option]);
  const flatPriceText = [
    `${price}\uB9CC\uC6D0`,
    flatPriceList.length > 0 && `(${flatPriceList.join(",")} \uD3EC\uD568)`
  ].filter(truthy).join("");
  const actualPriceText = actualPriceList.length > 0 && `${josa(actualPriceList.join(","), "\uC740/\uB294")} \uC0AC\uC6A9\uB7C9\uC5D0 \uB530\uB77C \uBCC4\uB3C4 \uBD80\uACFC`;
  return [flatPriceText, actualPriceText].filter(truthy).join("\n");
};
var getManageCostText = (article) => {
  if (article.writerType === "BROKER") {
    return getBrokerManageCostText(article);
  }
  const { manageCost, manageCostDescription, includeManageCostOptionV2, excludeManageCostOption } = article;
  const includeText = (includeManageCostOptionV2 || []).length > 0 ? `(${includeManageCostOptionV2.map((v) => ManageCostOptionText[v.option]).join(", ")} \uD3EC\uD568)` : "";
  return [
    manageCost && [`${manageCost}\uB9CC\uC6D0`, includeText].filter(truthy).join(" "),
    (excludeManageCostOption || []).length > 0 && `${excludeManageCostOption.map((v) => ManageCostOptionText[v]).join(", ")} \uBCC4\uB3C4`,
    manageCostDescription
  ].filter(Boolean).join("\n");
};
var getPremiumMoneyText = (premiumMoney) => {
  return premiumMoney ? billionConvert(premiumMoney, { withUnit: true }) : "\uC5C6\uC74C";
};
var QualitativeText = {
  GOOD_SUNLIGHT: "\u2600\uFE0F \uD587\uBE5B\uC774 \uC798 \uB4E4\uC5B4\uC694.",
  CONVENIENT_TRANSPORT: "\u{1F68C} \uAD50\uD1B5\uC774 \uD3B8\uB9AC\uD574\uC694.",
  MANY_AMENITIES: "\u{1F3EA} \uC8FC\uBCC0 \uD3B8\uC758\uC2DC\uC124\uC774 \uB9CE\uC544\uC694.",
  NICE_VIEW: "\u{1F5BC} \uC804\uB9DD\uC774 \uC88B\uC544\uC694.",
  NO_PESTS: "\u{1FAB2} \uBC8C\uB808\uAC00 \uC5C6\uC5B4\uC694.",
  AFFORDABLE_PRICE: "\u{1F4B8} \uC8FC\uBCC0\uBCF4\uB2E4 \uC800\uB834\uD574\uC694.",
  SAFE_FOR_SOLO: "\u26D1 \uD63C\uC790 \uC0B4\uC544\uB3C4 \uC548\uC804\uD574\uC694.",
  WITH_TERRACE: "\u26F3\uFE0F \uD14C\uB77C\uC2A4\uAC00 \uC788\uC5B4\uC694.",
  CLEAN_CONDITION: "\u2728 \uC9D1 \uC0C1\uD0DC\uAC00 \uAE68\uB057\uD574\uC694.",
  GOOD_SOUNDPROOFING: "\u{1F4A4} \uBC29\uC74C\uC774 \uC798\uB3FC\uC694."
};
var getQualitativeText = (qualitative) => {
  return QualitativeText[qualitative] || "";
};
var OrientationText = {
  EAST_FACING: "\uB3D9\uD5A5",
  WEST_FACING: "\uC11C\uD5A5",
  SOUTH_FACING: "\uB0A8\uD5A5",
  NORTH_FACING: "\uBD81\uD5A5",
  SOUTH_EAST_FACING: "\uB0A8\uB3D9\uD5A5",
  SOUTH_WEST_FACING: "\uB0A8\uC11C\uD5A5",
  NORTH_EAST_FACING: "\uBD81\uB3D9\uD5A5",
  NORTH_WEST_FACING: "\uBD81\uC11C\uD5A5"
};
var getOrientationText = (orientation) => {
  return OrientationText[orientation] || "";
};
var BuildingUsageText = {
  SINGLE_FAMILY_HOUSING: "\uB2E8\uB3C5\uC8FC\uD0DD",
  PUBLIC_HOUSING: "\uACF5\uB3D9\uC8FC\uD0DD",
  TYPE_1_NEIGHBORHOOD_LIVING_FACILITY: "\uC81C1\uC885 \uADFC\uB9B0\uC0DD\uD65C\uC2DC\uC124",
  TYPE_2_NEIGHBORHOOD_LIVING_FACILITY: "\uC81C2\uC885 \uADFC\uB9B0\uC0DD\uD65C\uC2DC\uC124",
  CULTURAL_AND_ASSEMBLY_FACILITIES: "\uBB38\uD654 \uBC0F \uC9D1\uD68C\uC2DC\uC124",
  RELIGIOUS_FACILITY: "\uC885\uAD50\uC2DC\uC124",
  SALE_FACILITY: "\uD310\uB9E4\uC2DC\uC124",
  TRANSPORTATION_FACILITY: "\uC6B4\uC218\uC2DC\uC124",
  MEDICAL_FACILITY: "\uC758\uB8CC\uC2DC\uC124",
  EDUCATION_AND_RESEARCH_FACILITY: "\uAD50\uC721\uC5F0\uAD6C\uC2DC\uC124",
  ELDERLY_FACILITY: "\uB178\uC720\uC790\uC2DC\uC124",
  STUDY_FACILITY: "\uC218\uB828\uC2DC\uC124",
  EXERCISE_FACILITY: "\uC6B4\uB3D9\uC2DC\uC124",
  OFFICE_FACILITY: "\uC5C5\uBB34\uC2DC\uC124",
  ACCOMMODATION_FACILITY: "\uC219\uBC15\uC2DC\uC124",
  RECREATION_FACILITY: "\uC704\uB77D\uC2DC\uC124",
  FACTORY: "\uACF5\uC7A5",
  WAREHOUSE_FACILITY: "\uCC3D\uACE0\uC2DC\uC124",
  HAZARDOUS_MATERIAL_FACILITY: "\uC704\uD5D8\uBB3C \uC800\uC7A5 \uBC0F \uCC98\uB9AC \uC2DC\uC124",
  DOOR_AND_PLANT_RELATED_FACILITY: "\uB3D9\uBB3C \uBC0F \uC2DD\uBB3C \uAD00\uB828 \uC2DC\uC124",
  SEWAGE_AND_WASTE_PROCESSING_FACILITY: "\uC790\uC6D0\uC21C\uD658 \uAD00\uB828 \uC2DC\uC124",
  CORRECTION_AND_MILITARY_FACILITY: "\uAD50\uC815\uC2DC\uC124",
  BROADCASTING_AND_COMMUNICATION_FACILITY: "\uBC29\uC1A1\uD1B5\uC2E0\uC2DC\uC124",
  POWER_GENERATION_FACILITY: "\uBC1C\uC804\uC2DC\uC124",
  CEMETERY_RELATED_FACILITY: "\uBB18\uC9C0 \uAD00\uB828 \uC2DC\uC124",
  TOURISM_AND_RECREATION_FACILITY: "\uAD00\uAD11 \uD734\uAC8C\uC2DC\uC124",
  FUNERAL_HOME: "\uC7A5\uB840\uC2DD\uC7A5",
  CAR_RELATED_FACILITY: "\uC790\uB3D9\uCC28 \uAD00\uB828 \uC2DC\uC124",
  TEMPORARY_BUILDING: "\uC57C\uC601\uC7A5\uC2DC\uC124"
};
var getBuildingUsageText = (buildingUsage) => {
  return BuildingUsageText[buildingUsage] || "";
};
var getRequiredOptions = (options) => {
  return options.filter(({ name }) => RequiredArticleOptionNames.some((v) => v === name));
};
var getOptionOptions = (options) => {
  return options.filter((v) => !RequiredArticleOptionNames.some((name) => name === v.name));
};
var getSubwayText = (subwayName, distanceMeter) => {
  if (!subwayName || !distanceMeter) {
    return "";
  }
  const parsedSubwayName = subwayName.replace(/\(.*?\)/g, "");
  const minute = Math.max(Math.ceil(distanceMeter / SUBWAY_DISTANCE_METER_PER_MINUTE), 1);
  return `${parsedSubwayName}\uC5ED \uB3C4\uBCF4 ${minute}\uBD84`;
};
var APPROVAL_YEARS = [2, 5, 10, 15, 25];
var getBuildingApprovalText = (buildingApprovalDate) => {
  if (!buildingApprovalDate) {
    return "";
  }
  const date = new Date(buildingApprovalDate);
  const yearGap = (/* @__PURE__ */ new Date()).getFullYear() - date.getFullYear();
  return `${formatDate(date, "yyyy\uB144 M\uC6D4 d\uC77C" /* BuildingApproval */)} (${yearGap}\uB144\uCC28)`;
};
var getBuildingApprovalDistance = (buildingApprovalDate) => {
  if (!buildingApprovalDate) {
    return;
  }
  const date = new Date(buildingApprovalDate);
  const yearGap = (/* @__PURE__ */ new Date()).getFullYear() - date.getFullYear();
  const roundedYear = APPROVAL_YEARS.find((v) => v >= yearGap);
  return roundedYear;
};
var getBuildingApprovalDistanceText = (buildingApprovalDate) => {
  const distance = getBuildingApprovalDistance(buildingApprovalDate);
  if (distance == void 0) {
    return "";
  }
  return `${distance}\uB144 \uC774\uB0B4`;
};
var getTradeTypeAndPrice = (tradeType, price, monthlyPay, options) => {
  const tradeTypeText = (options == null ? void 0 : options.withoutTradeType) ? "" : `${getArticleTradeTypeText(tradeType)} `;
  const priceText = billionConvert(price, { withUnit: Boolean(options == null ? void 0 : options.withUnit) });
  switch (tradeType) {
    case "MONTH":
    case "SHORT":
    case "YEAR":
      const monthlyPayText = billionConvert(monthlyPay, {
        withUnit: Boolean(options == null ? void 0 : options.withUnit)
      });
      return `${tradeTypeText}${priceText} / ${monthlyPayText}`;
    case "BORROW":
    case "BUY":
      return `${tradeTypeText}${priceText}`;
  }
};
var formatArea = (area, areaUnit = "m\xB2") => {
  return parseFloat(area.toFixed(areaUnit === "m\xB2" ? 2 : 1)).toString();
};
var getAreaUnitText = (unit) => {
  return unit === "\uD3C9" ? "\uD3C9\uC218" : "\uC81C\uACF1\uBBF8\uD130";
};
var areaText = (area, unit, options = { withUnit: true }) => {
  if (!area || Number(area) <= 0) {
    return "";
  }
  if (unit === "m\xB2") {
    return `${formatArea(Number(area), unit)}${options.withUnit ? "m\xB2" : ""}`;
  }
  return `${formatArea(Number(area) / \uD3C9\uB2F9_\uC81C\uACF1\uBBF8\uD130, unit)}${options.withUnit ? "\uD3C9" : ""}`;
};
var parseFloor = (floor) => String(parseInt(floor));
var getFloorText = (floor) => {
  return floor === SEMI_BASEMENT_FLOOR ? "\uBC18\uC9C0\uD558" : `${parseFloor(floor)}\uCE35`;
};

// src/services/buildingLedger/utils.ts
var findCorAddressMatchingBuildingLedgerTitle = (corAddress, ledgers) => {
  var _a;
  if (ledgers.length === 0) {
    return void 0;
  }
  if (ledgers.length === 1) {
    return ledgers[0];
  }
  const maybeDong = (_a = corAddress.match(/ 제?(\w+)동 /)) == null ? void 0 : _a[1];
  const maybeBuildingInfo = ledgers.find((v) => v.dongNm.includes(maybeDong != null ? maybeDong : ""));
  return maybeBuildingInfo;
};
var buildingLedgerTitleToArticleForm = (ledger) => {
  const rideUseElvt = ledger.rideUseElvtCnt && typeof ledger.rideUseElvtCnt === "number" ? ledger.rideUseElvtCnt : null;
  const grndFlr = ledger.grndFlrCnt && typeof ledger.grndFlrCnt === "number" ? ledger.grndFlrCnt : null;
  const useAprDay = ledger.useAprDay && typeof ledger.useAprDay === "number" ? ledger.useAprDay : null;
  return {
    options: rideUseElvt && rideUseElvt > 0 ? [
      {
        name: "ELEVATOR",
        value: "YES"
      }
    ] : [],
    topFloor: grndFlr ? String(grndFlr) : void 0,
    buildingApprovalDate: useAprDay ? parse(String(useAprDay), "yyyyMMdd", /* @__PURE__ */ new Date()) : void 0
  };
};

// src/services/bigpicture/index.ts
import sha256 from "sha256";
var ENDPOINT = {
  alpha: "https://realty.alpha.kr.karrotmarket.com/graphql",
  production: "https://realty.kr.karrotmarket.com/graphql"
};
var BigPictureUploader = class {
  constructor(config) {
    this.config = config;
  }
  async uploadImage({
    blob,
    file,
    image,
    uploadTarget
  }) {
    const { uploadUrl, originalId, thumbnailUrl } = await this.requestUploadUrl({
      type: uploadTarget,
      width: image.width,
      height: image.height,
      contentType: file.type,
      filepath: this.generateFilePath(file)
    });
    const response = await fetch(uploadUrl, {
      method: "PUT",
      body: blob,
      headers: {
        "Content-Type": file.type,
        "x-amz-meta-id": originalId
      }
    });
    if (!response.ok) {
      throw new Error(`Failed to upload file to S3: ${response.status} ${response.statusText}`);
    }
    return {
      id: originalId,
      thumbnail: thumbnailUrl
    };
  }
  async requestUploadUrl(input) {
    var _a;
    const auth = await this.config.authProvider();
    const response = await fetch(ENDPOINT[this.config.stage], {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ..."authToken" in auth && { "x-auth-token": auth.authToken },
        ..."accessToken" in auth && {
          "x-access-token": auth.accessToken,
          "x-refresh-token": auth.refreshToken
        }
      },
      body: JSON.stringify({
        query: `
          mutation RequestBigPictureImageUpload($input: RequestBigPictureImageUploadInput!) {
            requestBigPictureImageUpload(input: $input) {
              __typename
              ... on BigPictureImageUploadInfo {
                originalId
                uploadUrl
                thumbnailUrl
              }
              ... on ToastError {
                toastError {
                  message
                }
              }
            }
          }
        `,
        variables: {
          input
        }
      })
    });
    if (!response.ok) {
      throw new Error(`Failed to request upload URL: ${response.status} ${response.statusText}`);
    }
    const { data, errors } = await response.json();
    if ((errors == null ? void 0 : errors.length) > 0) {
      throw new Error(errors[0].message);
    }
    const result = data.requestBigPictureImageUpload;
    if (result.__typename === "ToastError") {
      throw new Error(((_a = result.toastError) == null ? void 0 : _a.message) || "Unknown error occurred");
    }
    if (result.__typename !== "BigPictureImageUploadInfo") {
      throw new Error("Unknown typename for requestBigPictureImageUpload");
    }
    if (!result.originalId || !result.uploadUrl || !result.thumbnailUrl) {
      throw new Error("Missing required fields in response");
    }
    return {
      originalId: result.originalId,
      uploadUrl: result.uploadUrl,
      thumbnailUrl: result.thumbnailUrl
    };
  }
  generateFilePath(file) {
    var _a;
    return `realty/articles/${sha256((_a = file.name) != null ? _a : "image")}_${Date.now()}.${file.type.replace("image/", "")}`;
  }
  async uploadImages(images) {
    const uploadedImages = images.map(({ image, file, uploadTarget }) => {
      return new Promise((resolve, reject) => {
        const onReceiveBlob = (blob) => {
          if (!blob) {
            return reject(new Error("[uploadImages] image blob does not exist"));
          }
          this.uploadImage({ blob, file, image, uploadTarget }).then(resolve).catch(reject);
        };
        image.toBlob(onReceiveBlob, file.type);
      });
    });
    const results = await Promise.allSettled(uploadedImages);
    return results.map((result) => result.status === "fulfilled" ? result.value : null);
  }
};

export {
  parseAddress,
  CoordinateFinder,
  parseAddressDetail,
  getJibunAddress,
  removeDetailFromAddress,
  checkIsDagaguRentalHouse,
  KakaoCategory,
  SEMI_BASEMENT_FLOOR,
  평당_제곱미터,
  ArticleUploadType,
  TradeTypes,
  MonthlyPayableTradeTypes,
  AdjustableTradeTypes,
  ArticleStatuses,
  OneRoomSalesTypes,
  ResidentSalesTypes,
  BuildingNameAvailableSalesTypes,
  SupplyAreaFirstSalesTypes,
  ArticleOptionValues,
  RequiredArticleOptionNames,
  EtcArticleOptionNames,
  WriterTypes,
  MainBuildingUsageTypes,
  EtcBuildingUsageTypes,
  BuildingUsageTypes,
  ManageCostOptions,
  ManageCostOptionText,
  제주도_구분_위도,
  getArticleMainTradeType,
  isJejuArticle,
  isOneOfSalesType,
  getArticleSubSalesTypeText,
  getArticleSalesTypeText,
  SalesTypeText,
  getFilterSalesTypeByArticleSalesType,
  getFloorTypeText,
  getArticleStatusText,
  getArticleTradeTypeText,
  getArticleOptionNameText,
  getArticleOptionValueText,
  getArticleParkingOptionText,
  getSaleTargetText,
  getWriterTypeText,
  getBuildingNameTitle,
  getAreaText,
  isStringNumberFalsy,
  getRoomText,
  getFloorsText,
  isSemiBasement,
  getMoveInDateText,
  getManageCostPrice,
  getBrokerManageCostText,
  getManageCostText,
  getPremiumMoneyText,
  getQualitativeText,
  getOrientationText,
  getBuildingUsageText,
  getRequiredOptions,
  getOptionOptions,
  getSubwayText,
  getBuildingApprovalText,
  getBuildingApprovalDistance,
  getBuildingApprovalDistanceText,
  getTradeTypeAndPrice,
  formatArea,
  getAreaUnitText,
  areaText,
  parseFloor,
  getFloorText,
  findCorAddressMatchingBuildingLedgerTitle,
  buildingLedgerTitleToArticleForm,
  BigPictureUploader
};
