import { config, STAGE } from '@/config';
import { createMinikarrotScheme } from '@daangn/webview-link-router';
import { memo, useEffect } from 'react';
import logger from '@/utils/Logger';
import QRDialog from '@/components/Dialog/QRDialog';

type Props = {
  articleId: string;
  isOpen: boolean;
  close: () => void;
};

const ArticleQRDialog = ({ articleId, isOpen, close }: Props) => {
  useEffect(() => {
    logger.track('show_article_qr_dialog', { article_id: articleId });
  }, []);

  const appScheme = createMinikarrotScheme({
    url: `${config.webviewUrl}/articles/${articleId}?referrer=realty-ceo`,
    stage: STAGE,
  });

  return (
    <QRDialog
      url={appScheme}
      title="당근 앱으로 열기"
      description={
        <>
          모바일에서 이용할 수 있어요. <br />
          휴대전화 카메라나 당근 앱으로 QR코드를 촬영해 주세요.
        </>
      }
      isOpen={isOpen}
      close={close}
    />
  );
};

export default memo(ArticleQRDialog);
