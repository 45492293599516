import { Badge, Button } from '@daangn/carotene';
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import { useOverlay } from '@toss/use-overlay';
import { config, STAGE } from '@/config';
import { createMinikarrotScheme } from '@daangn/webview-link-router';
import useAppQuery from '@/hooks/useAppQuery';
import logger from '@/utils/Logger';
import RequestVerifyDialog from '@/routes/_protected/articles/-components/RequestVerifyDialog';
import QRDialog from '@/components/Dialog/QRDialog';

type Props = {
  createdArticle: {
    originalId: string;
    corRealtyId: string | null;
  };
  onWriteMore: () => void;
};

function ArticleCreateComplete({ createdArticle, onWriteMore }: Props) {
  const { viewer } = useAppQuery();
  const isOwnerVerified = !!viewer?.bizProfileBroker?.bizProfile?.verificationInfo?.ownerVerified;

  return (
    <div className="flex flex-col items-center justify-center gap-6 pt-5 text-center md:pt-[200px]">
      <img
        style={{ width: 116, height: 120 }}
        src="https://asset-town.krrt.io/production/illust/ccea2e75-8732-4647-b41d-28d2bd5666b1/10b01f333feecd723b9bca674cd544b5e96b8bd4.webp"
      />
      {isOwnerVerified ? (
        <CompleteSuccess createdArticle={createdArticle} onWriteMore={onWriteMore} />
      ) : (
        <OwnerNotVerified />
      )}
    </div>
  );
}

export default ArticleCreateComplete;

function CompleteSuccess({ createdArticle, onWriteMore }: Props) {
  const [showRequestVerifyDialog, setShowRequestVerifyDialog] = useState(false);

  const handleClickRequestVerify = () => {
    logger.track('click_request_verify', {});

    setShowRequestVerifyDialog(true);
  };
  return (
    <>
      <div className="flex flex-col gap-2">
        <h1 className="heading-large text-2xl">
          게시글 작성이 완료되었어요
          <br />
          매물번호: {createdArticle.originalId}
        </h1>
        <p className="body-medium-default">
          내 게시글은 당근 앱 {'>'} 나의 당근 {'>'} 부동산에서 확인하실 수 있어요.
        </p>
      </div>
      <div className="space-y-3">
        <div>
          <Button size="xlarge" onClick={onWriteMore} style={{ minWidth: 165 }}>
            매물 추가 등록하기
          </Button>
        </div>
        {createdArticle.corRealtyId && (
          <div>
            <Button
              type="button"
              size="xlarge"
              variant="neutral"
              onClick={handleClickRequestVerify}
              style={{ minWidth: 165 }}
            >
              집주인 인증 요청
            </Button>
          </div>
        )}
      </div>
      <RequestVerifyDialog
        isOpen={showRequestVerifyDialog}
        setOpen={setShowRequestVerifyDialog}
        articleId={createdArticle.originalId}
      />
    </>
  );
}

function OwnerNotVerified() {
  const { open } = useOverlay();
  const appQuery = useAppQuery();
  const intervalId = useRef<number | null>(null);

  const handleClickVerify = () => {
    const url = new URL(config.webviewUrl);
    url.searchParams.set('referrer', 'realty-ceo');
    url.searchParams.set('action', 'biz_profile_owner_verify');

    const appScheme = createMinikarrotScheme({
      url: url.toString(),
      stage: STAGE,
    });

    logger.track('click_article_new_biz_profile_owner_verify', {});

    open(({ isOpen, close }) => (
      <QRDialog
        title="당근 앱으로 열기"
        description={
          <>
            본인인증은 모바일에서 가능해요.
            <br />
            휴대전화 카메라나 당근 앱으로 QR코드를 촬영해 주세요.
          </>
        }
        url={appScheme}
        isOpen={isOpen}
        close={close}
      />
    ));

    intervalId.current = window.setInterval(() => {
      appQuery.refresh();
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (intervalId.current) {
        window.clearInterval(intervalId.current);
      }
    };
  }, []);

  return (
    <>
      <div className="flex flex-col justify-center gap-3">
        <h1 className="heading-large flex items-center justify-center gap-1.5 text-2xl">
          <Badge tone="negative" variant="weak" size="medium">
            미노출
          </Badge>
          <span>게시글 작성이 완료되었어요 </span>
        </h1>
        <p className="body-medium-default">
          개업공인중개사의 본인인증이 완료되지 않은 비즈프로필이에요.
          <br />
          매물 노출을 위해 본인인증이 필요해요.
        </p>
      </div>
      <div className="flex justify-center">
        <div>
          <Button type="button" size="xlarge" variant="neutral" onClick={handleClickVerify}>
            개업공인중개사 본인인증 진행하기
          </Button>
        </div>
      </div>
    </>
  );
}
