/**
 * @generated SignedSource<<7975dd44c7539bc04527d649c8c6cd6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ViewWriteArticleStatusInputEnum = "HIDDEN" | "ON_SALES" | "TRADED";
export type ViewerWriteArticleStatusGroupByCountInput = {
  addressKeyword?: string | null;
};
export type mySalesHistoryListQuery$variables = {
  input?: ViewerWriteArticleStatusGroupByCountInput | null;
};
export type mySalesHistoryListQuery$data = {
  readonly viewerWriteArticleStatusGroupByCount: ReadonlyArray<{
    readonly count: number;
    readonly status: ViewWriteArticleStatusInputEnum;
  }>;
};
export type mySalesHistoryListQuery = {
  response: mySalesHistoryListQuery$data;
  variables: mySalesHistoryListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ViewerWriteArticleStatusGroupByCount",
    "kind": "LinkedField",
    "name": "viewerWriteArticleStatusGroupByCount",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "mySalesHistoryListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "mySalesHistoryListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "83f170fdd699774a5d975259e22d96cb",
    "id": null,
    "metadata": {},
    "name": "mySalesHistoryListQuery",
    "operationKind": "query",
    "text": "query mySalesHistoryListQuery(\n  $input: ViewerWriteArticleStatusGroupByCountInput\n) {\n  viewerWriteArticleStatusGroupByCount(input: $input) {\n    count\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "b66c304ee340f90ec0be6906ad6510a4";

export default node;
