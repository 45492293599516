import { useState, useRef, useEffect, Suspense } from 'react';
import { MultilineTextField } from '@/components/ui/MultilineTextField';
import { useArticleForm } from '@/routes/_protected/articles/-contexts';
import { captureException } from '@sentry/react';
import {
  TradeTypeEnum,
  ArticleOptionNameEnum,
  ArticleOptionValueEnum,
  type ArticleBuildingOrientationEnum,
  type SalesTypeEnum,
} from '@/types/schemaEnums';
import logger from '@/utils/Logger';
import { ActionHistoryKey, realtyLocalStorage } from '@/utils/localStorage';
import { graphql, useMutation, Disposable } from 'react-relay';
import type { AiConversionDialogMutation } from '@/__generated__/AiConversionDialogMutation.graphql';
import LoadingLottie from '@/components/LoadingLottie';
import { Dialog } from '@/components/Dialog';
import { Button, ProgressCircle } from '@daangn/carotene';

type StartStepProps = {
  onNext: () => void;
};

const StartStep = ({ onNext }: StartStepProps) => {
  return (
    <>
      <div className="flex flex-col gap-7">
        <Dialog.Header>
          <Dialog.Title>매물 정보 간편 입력하기</Dialog.Title>
          <Dialog.Description>
            아래 이미지와 같이 평소 메모장이나 메신저에 관리하던 매물 정보를 복사하고 붙여넣기만
            하면 글쓰기 항목에 자동으로 정보가 입력돼요.
          </Dialog.Description>
        </Dialog.Header>
        <img
          className="w-full rounded-md"
          src="https://assetstorage.krrt.io/1031841233991644238/43c2b122-7faa-4d37-b18a-cdcf316e559e/width=2873,height=1977.png"
        />
      </div>
      <Dialog.Footer>
        <Dialog.ButtonGroup>
          <div className="spacer" />
          <Dialog.Close asChild>
            <Button type="button" size="large" variant="neutral">
              취소
            </Button>
          </Dialog.Close>
          <Button type="button" size="large" variant="brand" onClick={onNext}>
            자동 입력 시작하기
          </Button>
        </Dialog.ButtonGroup>
      </Dialog.Footer>
    </>
  );
};

type WriteStepProps = {
  onComplete: (text: string) => void;
};

const WriteStep = ({ onComplete }: WriteStepProps) => {
  const [text, setText] = useState('');

  const handleClickPaste = () => {
    logger.track('click_ai_conversion_paste_button', {});

    navigator.clipboard.readText().then((text) => {
      setText(text);
    });
  };

  const handleClickComplete = () => {
    onComplete(text);
  };

  return (
    <>
      <div className="flex flex-col gap-7 pb-7">
        <Dialog.Title className="flex text-2xl font-bold">
          <div>매물 정보 간편 입력하기</div>
          <div className="spacer" />
          <Button type="button" size="medium" variant="neutral" onClick={handleClickPaste}>
            텍스트 붙여넣기
          </Button>
        </Dialog.Title>
        <div className="flex flex-col gap-3">
          <MultilineTextField
            placeholder="평소 메모장이나 메신저에 관리하던 매물 정보를 복사하고(ctrl+c) 붙여넣기(ctrl+v)만 하면 글쓰기 항목에 자동으로 정보가 입력돼요."
            value={text}
            onChange={setText}
            UNSAFE_className="[&_textarea]:max-h-[491px] [&_textarea]:min-h-[200px]"
            minHeight={200}
          />
        </div>
      </div>
      <Dialog.Footer>
        <div className="flex gap-2">
          <div className="spacer" />
          <Dialog.Close asChild>
            <Button type="button" size="large" variant="neutral">
              취소
            </Button>
          </Dialog.Close>
          <Button type="button" size="large" variant="brand" onClick={handleClickComplete}>
            입력 완료
          </Button>
        </div>
      </Dialog.Footer>
    </>
  );
};

const LoadingStep = () => {
  return (
    <>
      <div className="flex flex-col gap-8 pb-7">
        <div className="flex justify-center">
          <Suspense fallback={null}>
            <LoadingLottie />
          </Suspense>
        </div>
        <p className="text-center text-xl font-bold">
          매물 정보 간편 입력 중이에요.
          <br />
          잠시만 기다려주세요!
        </p>
      </div>
      <Dialog.Footer>
        <Dialog.ButtonGroup>
          <div className="spacer" />
          <Dialog.Close asChild>
            <Button type="button" size="large" variant="neutral">
              취소
            </Button>
          </Dialog.Close>
          <Button type="button" size="large" variant="brand">
            <ProgressCircle tone="staticWhite" size={14} />
          </Button>
        </Dialog.ButtonGroup>
      </Dialog.Footer>
    </>
  );
};

type DialogStatus = 'close' | 'start' | 'write' | 'loading';

type Props = {
  onConversionComplete: () => void;
};

const AiConversionDialog = ({ onConversionComplete }: Props) => {
  const { getValues, reset, handleSubmit } = useArticleForm();
  const disposableRef = useRef<Disposable>();
  const [generateAutoFill] = useMutation<AiConversionDialogMutation>(graphql`
    mutation AiConversionDialogMutation($content: String!) {
      generateAutoFillFormByPlainTextForBroker(plainText: $content) {
        addressInfo
        area
        availableParkingSpotsV2
        bathroomCnt
        buildingOrientation
        content
        floor
        manageCost
        manageCostDescription
        moveInDate
        options
        roomCnt
        salesType
        topFloor
        trades {
          description
          price
          monthlyPay
          tradeType
        }
      }
    }
  `);

  const [status, setStatus] = useState<DialogStatus>('close');

  const handleOpenChange = (open: boolean) => {
    if (open) {
      logger.track('click_ai_conversion_button', {});

      const hasSeenStartStep = realtyLocalStorage.getActionHistory(
        ActionHistoryKey.SeenAIConversionStartStep
      );

      setStatus(hasSeenStartStep ? 'write' : 'start');
    } else {
      disposableRef.current?.dispose();
      setStatus('close');
    }
  };

  const handleStart = () => {
    logger.track('click_ai_conversion_start_button', {});
    setStatus('write');
  };

  const handleWriteComplete = (text: string) => {
    logger.track('click_ai_conversion_complete_button', {});
    setStatus('loading');

    disposableRef.current = generateAutoFill({
      variables: { content: text },
      onCompleted: ({ generateAutoFillFormByPlainTextForBroker: data }) => {
        if (!data) {
          setStatus('write');
          window.alert('매물 정보를 자동으로 입력하는 중에 문제가 발생했어요.');
          return;
        }

        reset({
          ...getValues(),
          ...data,
          area: data.area ?? undefined,
          bathroomCnt: data.bathroomCnt ?? undefined,
          buildingOrientation:
            (data.buildingOrientation as ArticleBuildingOrientationEnum) ?? undefined,
          roomCnt: data.roomCnt ?? undefined,
          salesType: (data.salesType as SalesTypeEnum) ?? undefined,
          topFloor: data.topFloor ?? undefined,
          manageCost: data.manageCost ?? undefined,
          content: data.content ?? undefined,
          options: data.options.map((option) => ({
            name: option as ArticleOptionNameEnum,
            value: ArticleOptionValueEnum.Yes,
          })),
          trades: data.trades.map((trade) => ({
            ...trade,
            tradeType: trade.tradeType as TradeTypeEnum,
            price: trade.price,
            // 0 입력 대응
            monthlyPay: trade.monthlyPay || undefined,
          })),
          moveInDate: data.moveInDate ?? undefined,
        });
        handleSubmit(
          () => undefined,
          () => undefined
        )();

        setStatus('close');
        window.alert('정보를 자동 입력했어요. 나머지 필수 항목을 입력 후 매물을 등록해 주세요.');
        onConversionComplete();
      },
      onError: (error) => {
        setStatus('close');
        captureException(error);
        window.alert('매물 정보를 자동으로 입력하는 중에 문제가 발생했어요.');
      },
    });
  };

  useEffect(() => {
    if (status === 'start') {
      realtyLocalStorage.setActionHistory(ActionHistoryKey.SeenAIConversionStartStep);
    }
  }, [status]);

  return (
    <Dialog.Root open={status !== 'close'} onOpenChange={handleOpenChange}>
      <Dialog.Trigger asChild>
        <Button type="button" size="medium" variant="neutral">
          간편 매물 등록
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          onInteractOutside={(e) => {
            if (status === 'loading') {
              e.preventDefault();
            }
          }}
        >
          {status === 'start' && <StartStep onNext={handleStart} />}
          {status === 'write' && <WriteStep onComplete={handleWriteComplete} />}
          {status === 'loading' && <LoadingStep />}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default AiConversionDialog;
