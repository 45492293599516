import type { SearchJusoAddressAllUnitsQuery$data } from '@/__generated__/SearchJusoAddressAllUnitsQuery.graphql';
import { ProgressCircle, TextField } from '@daangn/carotene';
import { IconMagnifyingglassLine, IconXmarkLine } from '@daangn/react-monochrome-icon';
import { highlightText, useSearch } from '@daangn/realty-react';
import { useMemo } from 'react';

export type JusoUnit = { dongNm: string; floorNm: string; hoNm: string };

type Props = {
  units: SearchJusoAddressAllUnitsQuery$data['searchBuildingAllUnits'];
  onSelectUnit: (unit: JusoUnit) => void;
};

const getUnitText = (unit: JusoUnit) => {
  return [unit.dongNm, unit.floorNm, unit.hoNm].filter(Boolean).join(' ');
};

const SelectJusoDongHo = ({ units, onSelectUnit }: Props) => {
  const { input, setInput, query, reset } = useSearch('');

  const matchingUnits = useMemo(() => {
    if (!query) {
      return units.map((unit) => ({
        displayText: getUnitText(unit),
        unit,
      }));
    }

    // 검색어 정규화: 특수문자 제거, 소문자 변환
    const sanitizedInput = query.toLowerCase().replace(/[^a-z0-9가-힣\s]/g, ' ');
    const searchWords = sanitizedInput.split(/\s+/).filter(Boolean);

    const matchedUnits = units
      .map((unit) => {
        const displayText = getUnitText(unit);
        const unitText = displayText.toLowerCase().replace(/[^a-z0-9가-힣\s]/g, ' ');

        // 검색어의 각 단어가 매칭되는 횟수를 점수로 계산
        const score = searchWords.reduce((matchCount, word) => {
          return unitText.includes(word) ? matchCount + 1 : matchCount;
        }, 0);

        return { displayText, unit, score };
      })
      .filter((item) => item.score > 0) // 매칭되는 항목만 필터링
      .sort((a, b) => b.score - a.score) // 매칭 단어가 많은 순으로 정렬
      .map((item) => ({
        displayText: item.displayText,
        unit: item.unit,
      }));

    // 매칭되는 항목이 없으면 전체 목록 표시
    return matchedUnits.length > 0
      ? matchedUnits
      : units.map((unit) => ({
          displayText: getUnitText(unit),
          unit,
        }));
  }, [query, units]);

  const handleSelectUnit = (unit: JusoUnit) => {
    onSelectUnit(unit);
    setInput(getUnitText(unit));
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="body-medium-strong">
        동, 호수를 선택해주세요 <span className="body-medium-default">(필수)</span>
      </div>
      <div className="group">
        <TextField
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="예시) A-1101 또는 A동 1101호"
          description="동, 호수는 나에게만 보여요"
          suffix={
            input ? (
              <IconXmarkLine size={22} onMouseDown={() => reset()} />
            ) : (
              <IconMagnifyingglassLine size={22} />
            )
          }
        />
        {input && (
          <div className="relative hidden group-has-[:focus]:block">
            <div className="rounded-1.5 bg-bg-neutral absolute top-2 max-h-60 w-full overflow-auto shadow-[0px_4px_6px_0px_rgba(0,0,0,0.15),0px_0px_3px_0px_rgba(0,0,0,0.08)]">
              {input !== query ? (
                <div className="flex h-60 items-center justify-center">
                  <ProgressCircle />
                </div>
              ) : (
                <div className="flex flex-col">
                  {matchingUnits.map((unit, i) => (
                    <p
                      key={i}
                      className="px-4 py-2"
                      onMouseDown={() => {
                        handleSelectUnit(unit.unit);
                      }}
                    >
                      {highlightText({
                        fullText: unit.displayText,
                        boldText: query,
                        className: 'body-small-strong text-blue600',
                      })}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectJusoDongHo;
