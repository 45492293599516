import { useController } from 'react-hook-form';
import { useArticleForm } from '@/routes/_protected/articles/-contexts';
import { useMemo } from 'react';
import { format } from 'date-fns';
import { DateFormat, checkCanMoveInNow } from '@daangn/realty-sdk';
import FieldLogger from '@/routes/_protected/articles/-components/FieldLogger';
import { Checkbox, TextField } from '@daangn/carotene';

const MoveInDateField = () => {
  const { control } = useArticleForm();
  const { field, fieldState } = useController({
    name: 'moveInDate',
    control,
  });
  const today = useMemo(() => format(new Date(), DateFormat.DateScalar), []);
  const canMoveInNow = checkCanMoveInNow(field.value ? new Date(field.value) : undefined);

  const handleMoveInDateNow = (canMoveInNow: boolean) => {
    if (canMoveInNow) {
      field.onChange(today);
    } else {
      field.onChange(undefined);
    }
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <FieldLogger name="move_in_date" eventParams={{}}>
        <TextField
          {...field}
          value={field.value}
          disabled={canMoveInNow}
          label="입주 가능일"
          type="date"
          invalid={!!fieldState.error}
          errorMessage={fieldState.error?.message}
        />
      </FieldLogger>
      <FieldLogger name="can_move_in_now" eventParams={{ isSelected: canMoveInNow }}>
        <Checkbox
          checked={canMoveInNow}
          onCheckedChange={handleMoveInDateNow}
          label="즉시 입주 가능"
        />
      </FieldLogger>
    </div>
  );
};

export default MoveInDateField;
