import type { ArticleFormRef } from '@/routes/_protected/articles/-components/ArticleForm';
import logger from '@/utils/Logger';
import { Button } from '@daangn/carotene';

const BANNER_IMAGE =
  'https://assetstorage.krrt.io/1138369647032855545/6a59aa4b-5957-41d3-ad11-2bb80acd8d6a/width=354.6,height=200.svg';

export type Section = 'new' | 'sales_my' | 'sales_biz';
const ReportBanner = ({
  formRef,
  section,
}: {
  section: Section;
  formRef?: React.RefObject<ArticleFormRef>;
}) => {
  const handleReportClick = () => {
    logger.track('click_report_banner', {
      section: section,
      form: formRef?.current?.getValues(),
    });

    window.open('https://admin-webapp.kr.karrotmarket.com/ad/user_surveys/19940', '_blank');
  };

  return (
    <div
      className="cursor-pointer rounded-md px-16 py-8"
      style={{ background: 'rgba(138, 135, 244, 0.10)' }}
      onClick={handleReportClick}
    >
      <div className="flex items-center gap-6">
        <img src={BANNER_IMAGE} />
        <div className="flex max-w-[330px] flex-col gap-1">
          <h3 className="heading-medium font-bold">당근부동산팀에 제안해주세요!</h3>
          <p className="body-medium-default text-fg-neutralMuted">
            글쓰기 폼에 오류 제보 또는 개선 제안이 있으신가요? 당근부동산팀에 제안해주세요.
          </p>
        </div>
        <div className="spacer" />
        <div className="flex items-center">
          <Button type="button" size="large" variant="neutral">
            제안하기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReportBanner;
