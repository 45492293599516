import useAppQuery from '@/hooks/useAppQuery';

const BrokerInfo = () => {
  const { viewer } = useAppQuery();

  if (!viewer?.bizProfileBroker) {
    return null;
  }

  const broker = viewer.bizProfileBroker;

  return (
    <div className="bg-bg-layerDefaultHover flex flex-col gap-4 rounded-lg p-4">
      {[
        { label: '중개소', value: broker.bizProfile.name },
        {
          label: '대표',
          value: broker.bizProfile.licenseOwnerName,
        },
        {
          label: '위치',
          value: broker.bizProfile.jibunAddress || broker.bizProfile.roadAddress,
        },
        {
          label: '사업자등록번호',
          value: broker.bizProfile.businessRegistrationNumber,
        },
        {
          label: '개설 등록번호',
          value: broker.bizProfile.licenseRegistrationNumber,
        },
      ]
        .filter((v) => !!v.value)
        .map(({ label, value }) => (
          <div className="flex items-start gap-5" key={label}>
            <span className="body-small-default text-fg-neutralMuted min-w-[100px]">{label}</span>
            <span className="body-small-strong text-fg-neutral text-right">{value}</span>
          </div>
        ))}
    </div>
  );
};

export default BrokerInfo;
