// src/utils/image.ts
import loadImage from "blueimp-load-image";
var loadImageAsCanvas = async (file) => {
  const data = await loadImage(file, {
    maxWidth: 1920,
    maxHeight: 1920,
    canvas: true
  });
  return data;
};

// src/utils/color.ts
function checkIsLightColor(color) {
  let r, g, b;
  if (color.match(/^rgb/)) {
    const rgb = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    if (!rgb) return false;
    r = rgb[1];
    g = rgb[2];
    b = rgb[3];
  } else {
    const hexColor = +("0x" + color.slice(1).replace(color.length < 5 ? /./g : "", "$&$&"));
    r = hexColor >> 16;
    g = hexColor >> 8 & 255;
    b = hexColor & 255;
  }
  const hsp = Math.sqrt(
    0.299 * (Number(r) * Number(r)) + 0.587 * (Number(g) * Number(g)) + 0.114 * (Number(b) * Number(b))
  );
  return hsp > 127.5;
}

export {
  loadImageAsCanvas,
  checkIsLightColor
};
