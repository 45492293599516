import * as React from 'react';
import { createContext } from '@/contexts/createContext';
import classNames from 'classnames';
import DeleteButton from '@/components/DeleteButton';
import { IconArrowClockwiseCircularLine } from '@daangn/react-monochrome-icon';
import { ProgressCircle } from '@daangn/carotene';

type UploadImageContextType = {
  isUploading?: boolean;
  isError?: boolean;
};
const [UploadImageProvider] = createContext<UploadImageContextType>('UploadImage');

type UploadImageRootProps = React.HTMLAttributes<HTMLDivElement> & {
  isUploading?: boolean;
  isError?: boolean;
};
const UploadImageRoot = React.forwardRef<HTMLDivElement, UploadImageRootProps>(
  ({ isUploading, isError, className, children, ...props }, ref) => {
    return (
      <UploadImageProvider isUploading={isUploading} isError={isError}>
        <div
          ref={ref}
          className={classNames(
            'border-bg-overlay-low relative h-[140px] w-[140px] rounded-md border',
            className
          )}
          {...props}
        >
          {children}
          {isUploading && (
            <div className="bg-bg-neutral-inverted absolute left-0 top-0 h-full w-full rounded-md opacity-60">
              <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                <ProgressCircle />
              </div>
            </div>
          )}
          {isError && (
            <div className="bg-bg-neutral-inverted absolute left-0 top-0 h-full w-full rounded-md opacity-60">
              <div className="text-staticWhite absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center text-center">
                <IconArrowClockwiseCircularLine size={40} />
                <div className="text-gray50">재시도</div>
              </div>
            </div>
          )}
        </div>
      </UploadImageProvider>
    );
  }
);

type UploadImageImageProps = React.ComponentProps<'div'>;
const UploadImageImage = ({ className, children, ...props }: UploadImageImageProps) => {
  return (
    <div
      className={classNames(
        "h-full w-full overflow-hidden rounded-md bg-[url('https://assetstorage.krrt.io/1138369647032855545/3a9161a3-0e89-40fd-860a-f4878bc7edd7/width=210,height=210.png')] bg-cover",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

type UploadImageDeleteButtonProps = React.ComponentProps<typeof DeleteButton>;
const UploadImageDeleteButton = ({ onClick, ...props }: UploadImageDeleteButtonProps) => {
  return (
    <div className="absolute right-[-12px] top-[-12px]">
      <DeleteButton onClick={onClick} {...props} />
    </div>
  );
};

export { UploadImageRoot, UploadImageImage, UploadImageDeleteButton };
