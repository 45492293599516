import { MAX_IMAGE_COUNT } from '@/constants/article';
import useImageUpload from '@/hooks/useImageUpload';
import Field from '@/routes/_protected/articles/-components/Field';
import FieldLogger from '@/routes/_protected/articles/-components/FieldLogger';
import ImageSort from '@/routes/_protected/articles/-components/ImageSort';
import { useArticleForm } from '@/routes/_protected/articles/-contexts';
import { IconPlusLine } from '@daangn/react-monochrome-icon';
import { useController } from 'react-hook-form';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers';
import UploadImage from '@/components/ui/UploadImage';
import { Button } from '@daangn/carotene';

const ImageField = () => {
  const { control } = useArticleForm();
  const images = useController({ control, name: 'images' });

  const { handleInputFileChange, previewImages } = useImageUpload({
    images: images.field.value ?? [],
    onChangeImages: images.field.onChange,
    maxImageCount: MAX_IMAGE_COUNT,
  });

  return (
    <Field label="사진" {...images.fieldState}>
      <div className="flex flex-col gap-6">
        <FieldLogger name="image" eventParams={{}}>
          <label
            htmlFor="image-upload"
            className="inline-block cursor-pointer *:pointer-events-none"
          >
            <Button
              type="button"
              variant="neutral"
              icon={<IconPlusLine width={12} height={12} />}
              name={images.field.name}
            >
              이미지 추가
            </Button>
          </label>
        </FieldLogger>
        <input
          hidden
          id="image-upload"
          type="file"
          accept="image/*"
          multiple
          onChange={handleInputFileChange}
        />
        {(!!images.field.value?.length || !!previewImages.length) && (
          <div className="my[-12px] flex gap-4 overflow-x-auto py-[12px]">
            <ImageSort
              images={images.field.value ?? []}
              onSortChange={images.field.onChange}
              modifiers={[restrictToFirstScrollableAncestor]}
              strategy={horizontalListSortingStrategy}
            >
              {({ image, sortable, style }, index) => (
                <UploadImage
                  key={image.id}
                  ref={sortable.setNodeRef}
                  className="border-bg-overlay-low relative aspect-square w-[140px] rounded-md border"
                  style={style}
                  onClickDelete={() => {
                    images.field.onChange(images.field.value?.filter((_, i) => i !== index));
                  }}
                  {...sortable.attributes}
                >
                  <div {...sortable.listeners}>
                    <img
                      className="h-full w-full rounded-md object-cover"
                      src={image.thumbnail}
                      alt="article"
                    />
                  </div>
                </UploadImage>
              )}
            </ImageSort>
            {previewImages.map((image) => (
              <UploadImage key={image.id} isUploading>
                <img className="h-full w-full object-cover" src={image.previewSrc} />
              </UploadImage>
            ))}
          </div>
        )}
      </div>
    </Field>
  );
};

export default ImageField;
