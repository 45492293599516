import Header from '@/components/Header';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import PageError, { PageErrorType } from '@/components/PageError';
import { lazy, Suspense, useEffect } from 'react';
import { STAGE } from '@/config';
import DefaultToaster from '@/components/DefaultToaster';
import { type GraphQLTaggedNode } from 'relay-runtime';
import { type PreloadedQuery } from 'react-relay';
import type { mainQuery, mainQuery$data } from '@/__generated__/mainQuery.graphql';
import useAppQuery from '@/hooks/useAppQuery';
import { setContext } from '@sentry/react';
import logger from '@/utils/Logger';
import type { RelayObservable } from 'relay-runtime/lib/network/RelayObservable';
import { OverlayProvider } from '@toss/use-overlay';

const TanStackRouterDevtools =
  STAGE === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        }))
      );

type RouterContext = {
  appQuery: {
    node: GraphQLTaggedNode;
    preloaded: PreloadedQuery<mainQuery>;
    observable: RelayObservable<mainQuery$data>;
    refresh: () => Promise<mainQuery$data | undefined>;
  };
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootPage,
  notFoundComponent: () => <PageError errorType={PageErrorType.NotFound} />,
  errorComponent: () => <PageError errorType={PageErrorType.InternalServerError} />,
});

function RootPage() {
  const { viewer } = useAppQuery();

  useEffect(() => {
    if (viewer) {
      const user = {
        id: String(viewer.originalId),
        bizProfileBroker: viewer.bizProfileBroker ?? undefined,
        isDebugUser: viewer.isDebugUser,
      };

      setContext('user', user);

      logger.setUser(user);
      window.clarity?.init?.(String(user?.id), user.bizProfileBroker ?? {});
    }
  }, [viewer, viewer?.bizProfileBroker]);

  return (
    <>
      <OverlayProvider>
        <Header />
        <div className="container h-full max-w-screen-md px-2 py-14 md:mx-auto md:px-0">
          <Suspense>
            <Outlet />
          </Suspense>
        </div>
        <DefaultToaster />
        <Suspense>
          <TanStackRouterDevtools />
        </Suspense>
      </OverlayProvider>
    </>
  );
}
