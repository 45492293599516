import {
  BizProfileArticleStatusInputEnum,
  ViewWriteArticleStatusInputEnum,
} from '@/types/schemaEnums';
import logger from '@/utils/Logger';
import { SelectableFormChip } from '@daangn/carotene';

export const SalesHistoryTabs = [
  {
    status: 'ON_SALES',
    label: '판매중',
  },
  {
    status: 'TRADED',
    label: '거래완료',
  },
  {
    status: 'HIDDEN',
    label: '숨김',
  },
] as const satisfies { status: `${ViewWriteArticleStatusInputEnum}`; label: string }[];

// codegen.yml - enumsAsTypes: false 설정으로 인해, union type 으로 사용하기 위해서 String Literal 사용
export type SalesHistoryStatus =
  | `${ViewWriteArticleStatusInputEnum}`
  | `${BizProfileArticleStatusInputEnum}`;

type ChipListProps = {
  selectedStatus: SalesHistoryStatus;
  statusGroupByCount: readonly { status: SalesHistoryStatus; count: number }[];
  onSelectedStatusChange: (status: SalesHistoryStatus) => void;
};

const ChipList = ({
  selectedStatus,
  onSelectedStatusChange,
  statusGroupByCount,
}: ChipListProps) => {
  const handleChange = (status: SalesHistoryStatus) => {
    logger.track('click_sales_history_tab', { status: status, path: window.location.pathname });
    onSelectedStatusChange(status);
  };

  return (
    <div className="flex gap-[12px]">
      {SalesHistoryTabs.map(({ label, status }) => {
        const group = statusGroupByCount.find((v) => v.status === status);

        return (
          <SelectableFormChip
            key={status}
            onChange={() => handleChange(status)}
            checked={selectedStatus === status}
          >
            {label} {group?.count ?? 0}
          </SelectableFormChip>
        );
      })}
    </div>
  );
};

export default ChipList;
