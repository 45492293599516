import Skeleton from './Skeleton';

const ArticleItemSkeleton = () => {
  return (
    <div className="rounded-3 border-1 border-gray300 bg-bg-layerDefault p-5">
      <div className="flex cursor-pointer gap-4">
        <Skeleton.Box width={118} height={118} />

        <div className="flex flex-1 flex-col gap-1">
          <div className="flex flex-col items-start gap-1.5">
            <Skeleton.Text className="body-small-default">매물번호 764355</Skeleton.Text>
            <Skeleton.Text className="heading-small">월세 3,000 / 58</Skeleton.Text>
          </div>

          <div>
            <Skeleton.Text className="body-small-default mt-0.5">
              오픈형 원룸 ⸱ 25평 ⸱ 8층
            </Skeleton.Text>
            <Skeleton.Text className="body-xsmall-default mt-1">
              끌올 3시간 전 · 당근동
            </Skeleton.Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleItemSkeleton;
