import type {
  SearchJusoAddressAllUnitsQuery,
  SearchJusoAddressAllUnitsQuery$data,
} from '@/__generated__/SearchJusoAddressAllUnitsQuery.graphql';
import MainEnvironment from '@/relay/environment';
import type { JusoAddressResult } from '@/routes/_protected/articles/-components/SearchJusoAddressResult';
import SearchJusoAddressResult from '@/routes/_protected/articles/-components/SearchJusoAddressResult';
import SelectJusoDongHo, {
  type JusoUnit,
} from '@/routes/_protected/articles/-components/SelectJusoDongHo';
import { Badge, ProgressCircle, TextField } from '@daangn/carotene';
import { IconMagnifyingglassLine, IconXmarkLine } from '@daangn/react-monochrome-icon';
import { useSearch } from '@daangn/realty-react';
import { captureException } from '@sentry/react';
import { Suspense, useState } from 'react';
import toast from 'react-hot-toast';
import { fetchQuery, graphql } from 'relay-runtime';

const Loading = () => (
  <div className="flex items-center justify-center py-5">
    <ProgressCircle />
  </div>
);

export type JusoAddressForm = {
  baseAddress: JusoAddressResult;
  unit?: {
    dongNm: string;
    floorNm: string;
    hoNm: string;
  };
};

type Props = {
  setIsFetchingUnit: (isFetchingUnit: boolean) => void;
  onComplete: (jusoAddress: JusoAddressForm) => void;
};

const SearchJusoAddress = ({ setIsFetchingUnit, onComplete }: Props) => {
  const { input, setInput, query, reset } = useSearch('');
  const [jusoAddress, setJusoAddress] = useState<JusoAddressForm | null>(null);
  const [allUnits, setAllUnits] = useState<
    SearchJusoAddressAllUnitsQuery$data['searchBuildingAllUnits']
  >([]);

  const handleOnSelectAddress = async (address: JusoAddressResult) => {
    setInput('');

    setJusoAddress({
      baseAddress: address,
      unit: undefined,
    });
    setAllUnits([]);
    setIsFetchingUnit(true);
    try {
      const response = await fetchQuery<SearchJusoAddressAllUnitsQuery>(
        MainEnvironment,
        graphql`
          query SearchJusoAddressAllUnitsQuery($data: SearchAddressesBaseDataInput!) {
            searchBuildingAllUnits(data: $data) {
              dongNm
              floorNm
              hoNm
            }
          }
        `,
        {
          data: {
            admCd: address.admCd,
            buldMnnm: address.buldMnnm,
            buldSlno: address.buldSlno,
            rnMgtSn: address.rnMgtSn,
            udrtYn: address.udrtYn,
          },
        }
      ).toPromise();

      if (!response || !response.searchBuildingAllUnits.length) {
        onComplete({
          baseAddress: address,
          unit: {
            dongNm: '',
            floorNm: '',
            hoNm: '',
          },
        });
        return;
      }

      setAllUnits(response.searchBuildingAllUnits);
    } catch (error: unknown) {
      toast.error('동,호수를 가져오는데 실패했어요. 주소를 다시 선택해주세요.');
      if (error instanceof Error) {
        error.message = `[juso unit 전체 가져오기 Error] ${error.message}`;
        captureException(error, {
          extra: {
            jusoAddress: address,
          },
        });
      }
      return;
    } finally {
      setIsFetchingUnit(false);
    }
  };

  const handleOnSelectUnit = (unit: JusoUnit) => {
    if (!jusoAddress) {
      toast.error('주소를 선택해주세요.');
      return;
    }

    onComplete({
      ...jusoAddress,
      unit,
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <div>
        <TextField
          placeholder="예) 한누리대로 411, 상암동 1595"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          autoFocus={true}
          suffix={
            input ? (
              <IconXmarkLine size={22} onMouseDown={() => reset()} />
            ) : (
              <IconMagnifyingglassLine size={22} />
            )
          }
        />
        {input && (
          <div className="relative">
            <div className="rounded-1.5 bg-bg-neutral absolute top-2 max-h-60 w-full overflow-auto shadow-[0px_4px_6px_0px_rgba(0,0,0,0.15),0px_0px_3px_0px_rgba(0,0,0,0.08)]">
              <Suspense fallback={<Loading />}>
                {input !== query ? (
                  <Loading />
                ) : (
                  <SearchJusoAddressResult query={query} onSelectItem={handleOnSelectAddress} />
                )}
              </Suspense>
            </div>
          </div>
        )}
        {!input && !jusoAddress && (
          <div className="mt-5 flex flex-col gap-3">
            <h3 className="heading-small">이렇게 검색해 보세요</h3>
            {[
              { title: '도로명 + 건물번호', description: '예: 테헤란로 4길 14' },
              { title: '동/읍/면/리 + 번지', description: '예: 역삼동 825-22' },
              { title: '건물명, 아파트명', description: '예: 교보타워' },
            ].map(({ title, description }, i) => (
              <div className="body-small-default flex flex-col gap-0.5" key={i}>
                <div>{title}</div>
                <div className="text-fg-neutralSubtle">{description}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      {jusoAddress && (
        <div className="flex flex-col gap-5">
          <div className="text-fg-neutral bg-bg-neutral body-small-default flex flex-col gap-1 rounded-md px-4 py-3">
            <div>{jusoAddress.baseAddress.roadAddress}</div>
            {jusoAddress.baseAddress.regionAddress && (
              <div className="flex items-center gap-1 rounded-sm">
                <Badge size="small" tone="neutral" className="bg-gray00">
                  지 번
                </Badge>
                <div>{jusoAddress.baseAddress.regionAddress}</div>
              </div>
            )}
          </div>
          {allUnits.length > 0 && (
            <SelectJusoDongHo units={allUnits} onSelectUnit={handleOnSelectUnit} />
          )}
        </div>
      )}
    </div>
  );
};

export default SearchJusoAddress;
