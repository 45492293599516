import {
  useImageUpload as useCommonImageUpload,
  type UseImageUploadParams,
} from '@daangn/realty-react/hooks';
import { ChangeEventHandler } from 'react';
import { validateImagesFileType } from '@/utils/image';
import { BigPictureUploader } from '@daangn/realty-sdk/services';
import { STAGE } from '@/config';
import { getAuthTokens } from '@/utils/cookie';

type Params = Omit<UseImageUploadParams, 'bigPictureUploader' | 'uploadTarget'>;

const bigPictureUploader = new BigPictureUploader({
  stage: STAGE,
  authProvider: () => {
    const { accessToken, refreshToken } = getAuthTokens();

    return Promise.resolve({
      accessToken: accessToken ?? '',
      refreshToken: refreshToken ?? '',
    });
  },
});

const useImageUpload = (params: Params) => {
  const { handleUploadImageFiles, previewImages, isUploading } = useCommonImageUpload({
    ...params,
    bigPictureUploader,
    events: {
      ...params.events,
      onError: (error) => {
        params.events?.onError?.(error);
        window.alert(error.message);
      },
    },
  });

  const handleInputFileChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const files = e.target.files;
    if (!files) return;
    const filesArray = Array.from(files);

    if (!validateImagesFileType(filesArray)) {
      window.alert('지원하지 않는 포맷이에요');
      return;
    }

    await handleUploadImageFiles(filesArray);
    e.target.value = '';
  };

  return { handleInputFileChange, previewImages, isUploading };
};

export default useImageUpload;
