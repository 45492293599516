import InfiniteLoading from '@/components/InfinteLoading';
import { config } from '@/config';
import useSearchJusoAddress, { type JusoAddressNode } from '@/hooks/useSearchJusoAddress';
import type { Coordinate } from '@/types/map';
import { Badge } from '@daangn/carotene';
import { highlightText } from '@daangn/realty-react/utils';
import { CoordinateFinder } from '@daangn/realty-sdk';

export type JusoAddressResult = JusoAddressNode & { coordinate: Coordinate };

type Props = {
  query: string;
  onSelectItem: (address: JusoAddressResult) => void;
};

const coordinateFinder = new CoordinateFinder({
  kakaoMapKey: config.kakaoRestKey,
  googleMapKey: config.googleMapKey,
});

const SearchJusoAddressResult = ({ query, onSelectItem }: Props) => {
  const { data, hasNext, loadNext } = useSearchJusoAddress({
    query,
  });

  const items = data.searchAddress.edges;

  const handleSelectItem = async (node: JusoAddressNode) => {
    const coordinate = await coordinateFinder.findByAddress(node.roadAddress);

    onSelectItem({
      ...node,
      coordinate: {
        lon: coordinate[0].substring(0, 12),
        lat: coordinate[1].substring(0, 12),
      },
    });
  };

  if (items.length === 0) {
    return (
      <div className="p-4">
        <div className="body-small-default">검색 결과가 없어요. 다시 검색해 주세요.</div>
      </div>
    );
  }

  return (
    <div>
      {items.map(({ node }, i) => {
        return (
          <div
            key={i}
            className="body-small-default flex cursor-pointer flex-col gap-1 px-4 py-3"
            onClick={() => handleSelectItem(node)}
          >
            <p>
              {highlightText({
                fullText: node.roadAddress,
                boldText: query,
                tag: 'span',
                className: 'text-accent',
              })}
            </p>
            {node.regionAddress && (
              <div className="flex items-center gap-1">
                <Badge size="small" tone="neutral">
                  지 번
                </Badge>
                <div>{node.regionAddress}</div>
              </div>
            )}
          </div>
        );
      })}
      <InfiniteLoading hasNext={hasNext} onLoadMore={() => loadNext(20)} />
    </div>
  );
};

export default SearchJusoAddressResult;
